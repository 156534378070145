import { Menu } from '@mui/icons-material';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';

const HomeHeader = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>

            <div className='Home_header'>
                <div className='Home_header_cs'>
                    <Button variant="primary" className='Menu__bar_icon' onClick={handleShow}>
                        <Menu />
                    </Button>
                </div>
            </div>


            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>More services</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='ul__as'>
                        <ul className='nav flex-column'>
                            <li className='nav-item'>
                                <Link to="/" className='nav-link' onClick={() => handleClose()}>Home</Link>
                            </li>
                            <li className='nav-item'>
                                <Link to="/about" className='nav-link' onClick={() => handleClose()}>About</Link>
                            </li>

                            <li className='nav-item'>
                                <Link to="/privacy-policy" className='nav-link' onClick={() => handleClose()}> Privacy Policy</Link>
                            </li>
                            <li className='nav-item'>
                                <Link to="/terms-conditions" className='nav-link' onClick={() => handleClose()}>terms and conditions </Link>
                            </li>

                            <li className='nav-item'>
                                <Link to="/contact-us" className='nav-link' onClick={() => handleClose()}>Contact Us </Link>
                            </li>
                        </ul>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default HomeHeader;
