import React, { useEffect, useRef, useState } from 'react';
import Marquee from "react-fast-marquee";
import profit3 from '../../Assets/Image/profit3.png';
import profit4 from '../../Assets/Image/profit4.png';
import profit5 from '../../Assets/Image/profit5.png';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import income from '../../Assets/Image/income.png';
import usd from '../../Assets/Image/usd.png';
import profit2 from '../../Assets/Image/profit2.png';
import wallet from '../../Assets/Image/wallet.png';
import hour24 from '../../Assets/Image/hour24.png';
import { api_url, titleDescription, website } from '../../config/Config';
import Table from 'react-bootstrap/Table';
import _fetch from '../../config/api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import scan_code from '../../Assets/Image/scan_code.png';
import 'react-responsive-modal/styles.css';
import toasted from '../../config/toast';
import Modal from 'react-responsive-modal';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import light_banner1 from '../../Assets/Image/light_banner1.png';
import dateformat from "dateformat";
import { ColorRing } from 'react-loader-spinner';
import { VolumeUpRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Dashboard = (props) => {

  var myVariable = Date.now();
  var makeDate = new Date(myVariable);
  var prev = new Date(makeDate.getFullYear(), makeDate.getMonth() - 1);


  const { t, i18n } = useTranslation();


  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });
  const [sponsorId, setSponsorId] = useState('');
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);


  // interface BannerDataType {
  //   banner_status: boolean;
  //   description: string;
  //   banner: string;
  // }

  const [loading, setLoading] = useState(true);
  const [fundValues, setFundValues] = useState([]);
  const [bannerData, setBannerData] = useState({ banner_status: "", description: "", banner: "" });
  const [newsData, setNewsData] = useState({ title: "", description: "", status: "" });
  const [tradeData, setTradeData] = useState([]);


  const [allUserData, setAllUserData] = useState({ boosterIncome: 0, totalIncome2: '', totalIncome: "", avaliableIncome: "", TotalDeposit: "", AvaliableDeposit: { fundValue: "", reBuyValue: "" }, directIncome: "", levelIncome: "", poolIncome: "", roiIncome: "", royaltyIncome: "", userInfo: { user_info: { name: "", income_limit: "", package: { current_package: "", previous_package: "", total_package: "" } } } });
  const [bussinessData, setBussinessData] = useState({ firstleg: 0, secondleg: 0, thirdleg: 0 });
  const [userRank, setUserRank] = useState({ rank: 0, rank_name: "" });


  // const [rankArray, setRankArray] = useState([

  //   { key: 0, rank_name: "Jade" },
  //   { key: 1, rank_name: "Peral" },
  //   { key: 2, rank_name: "Sapphire" },
  //   { key: 3, rank_name: "Sapphire 25" },
  // ]);


  const [rankArray, setRankArray] = useState([

    { "business": 2000, "total_business": 2000, "rank_name": "Jade", "rank_level": 1, "amount": 0 },
    { "business": 5000, "total_business": 7000, "rank_name": "Peral", "rank_level": 2, "amount": 200 },
    { "business": 10000, "total_business": 17000, "rank_name": "Sapphire", "rank_level": 3, "amount": 400 },
    { "business": 25000, "total_business": 42000, "rank_name": "Sapphire 25", "rank_level": 4, "amount": 1000 },
    { "business": 50000, "total_business": 92000, "rank_name": "Ruby", "rank_level": 5, "amount": 2000 },
    { "business": 100000, "total_business": 192000, "rank_name": "Emerald", "rank_level": 6, "amount": 4000 },
    { "business": 250000, "total_business": 442000, "rank_name": "Diamond", "rank_level": 7, "amount": 10000 },
    { "business": 500000, "total_business": 942000, "rank_name": "Double Diamond", "rank_level": 8, "amount": 20000 },
    { "business": 1000000, "total_business": 1942000, "rank_name": "Triple Diamond", "rank_level": 9, "amount": 40000 },
    { "business": 2000000, "total_business": 3942000, "rank_name": "Crown Diamond", "rank_level": 10, "amount": 80000 },
    { "business": 5000000, "total_business": 8942000, "rank_name": "Royal Crown Diamond", "rank_level": 11, "amount": 200000 },
    { "business": 10000000, "total_business": 18942000, "rank_name": "Vice President", "rank_level": 12, "amount": 400000 },
    { "business": 30000000, "total_business": 48942000, "rank_name": "Senior Vice President", "rank_level": 13, "amount": 1200000 },
    { "business": 50000000, "total_business": 98942000, "rank_name": "Global President", "rank_level": 14, "amount": 2000000 },
    { "business": 125000000, "total_business": 223942000, "rank_name": "Universal President", "rank_level": 15, "amount": 2500000 },
    { "business": 250000000, "total_business": 473942000, "rank_name": "Streakk President", "rank_level": 16, "amount": 3500000 },
  ]);


  const rankLevel = userRank?.rank;

  const nextRank = rankArray?.[rankLevel]?.rank_name;

  const MaxKingMaker = (rankArray?.[rankLevel]?.business) * 0.4;
  const MaxSecondLeg = (rankArray?.[rankLevel]?.business) * 0.4;
  const MaxOther = (rankArray?.[rankLevel]?.business) * 0.2;
  let PreviousMaxKingMaker = 0;
  let PreviousMaxSecondLeg = 0;
  let PreviousMaxOther = 0;
  if (rankLevel > 0) {
    const previousRank = rankLevel - 1;

    PreviousMaxKingMaker = (rankArray?.[previousRank]?.total_business) * 0.4;
    PreviousMaxSecondLeg = (rankArray?.[previousRank]?.total_business) * 0.4;
    PreviousMaxOther = (rankArray?.[previousRank]?.total_business) * 0.2;
  }

  const RankRoyality = (rankArray?.[rankLevel]?.amount);

  let location = useLocation();
  const navigate = useNavigate();
  const auth = localStorage.getItem('auth');
  const copyToClipboard = (address) => {

    var textField = document.createElement('textarea');
    textField.innerText = address;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toasted.success('Address copied successfull!');
  }

  useEffect(() => {

    // if (auth === "true") {
    (async () => {
      const data1 = await _fetch(`${api_url}user/userInfo/`, "GET", {});
      if (data1.status === 'success') {
        if (data1?.data?.data) {
          setSponsorId(data1?.data?.data?.username);
          setUserRank(data1?.data?.data?.user_info);
        }
      } else {
        localStorage.clear();
        navigate("/login");
      }
    })();
    // }
  }, [location.pathname]);




  useEffect(() => {
    getfundData();
    getUserData();
    getBannerData();
    getNewsData();
  }, []);

  const getfundData = async () => {

    let data = await _fetch(`${api_url}fund/getfund?limit=5`, "GET", {})

    if (data?.status === "success") {

      setFundValues(data?.data);
    }
  }

  const [todayProfit, setTodayProfit] = useState(0);
  const [lastMonthProfit, setlastMonthProfit] = useState(0);
  const [yesterdayProfit, setyesterdayProfit] = useState(0);
  const [currentMonthProfit, setcurrentMonthProfit] = useState(0);


  const getUserData = async () => {
    setLoading(true);
    let res = await _fetch(`${api_url}profile/dashboard`, "GET", {})

    if (res?.status === "success") {

      setAllUserData(res?.data);
      setBussinessData(res?.data?.business?.business);
      setTodayProfit(res?.data?.profitDetails?.todayProfit)
      setyesterdayProfit(res?.data?.profitDetails?.yesterdayProfit)
      setlastMonthProfit(res?.data?.profitDetails?.lastMonthProfit)
      setcurrentMonthProfit(res?.data?.profitDetails?.currentMonthProfit)
      setLoading(false);

    }
  }
  const data1 = [
    ["Task", "Hours per Day"],
    ["XRP", 11],
    ["Bitcoin", 2],
    ["Cardano", 2],
    ["Etherium", 2],

  ];

  const options = {
    title: "Current Incomes",
  };
  const data = [
    ["Task", "Hours per Day"],
    ["Direct Profit", allUserData?.directIncome],
    ["Layered Investment Returns", allUserData?.levelIncome],
    ["Profit on Investment", allUserData?.roiIncome],
    ["Royalty Profit", allUserData?.royaltyIncome],
    ["Eternal Global Pool Profit", allUserData?.poolIncome],

  ];


  const options1 = {
    title: "Trading",
    pieHole: 0.4,
    is3D: false,
  };



  const [info, setInfo] = useState({ sponsor_id: "" });
  const [userInfo, setUserInfo] = useState({ income_earn: 0, income_limit: 0 });

  const now = userInfo?.income_earn;

  useEffect(() => {
    getUserInfo();
    getTrdeData();
  }, []);

  const getUserInfo = async () => {
    let res = await _fetch(`${api_url}user/userInfo/`, "GET", {})
    if (res?.status === 'success') {
      setInfo(res?.data?.data);
      setUserInfo(res?.data?.data?.user_info);
    }
  }
  const [open3, setOpen3] = useState(false);

  const getBannerData = async () => {
    let res = await _fetch(`${api_url}profile/bannerdata`, "GET", {})
    if (res?.status === "success") {
      setOpen3(res?.data?.[0]?.user_banner?.banner_status);
      setBannerData(res?.data?.[0]?.user_banner);
    }
  }

  const getNewsData = async () => {
    let res = await _fetch(`${api_url}profile/newsData`, "GET", {})
    if (res?.status === "success") {
      setNewsData(res?.data);
    }
  }


  const getTrdeData = async () => {
    let res = await _fetch(`${api_url}report/trade-detail`, "GET", {})
    if (res?.status === "success") {
      setTradeData(res?.data);
    }
  }

  const closeModal3 = () => {
    setOpen3(false);
  };

  // const TradingViewWidget = () => {
  const containerRef = useRef(null);

  // useEffect(() => {
  //   if (tradeData.length > 0) {
  //     // Clean up the previous widget if it exists
  //     if (containerRef.current) {
  //       while (containerRef.current.firstChild) {
  //         containerRef.current.removeChild(containerRef.current.firstChild);
  //       }
  //     }

  //     // Create a new script element for TradingView widget
  //     const script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.async = true;
  //     script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'; // Updated widget type
  //     script.text = JSON.stringify({
  //       symbols: tradeData.map((item) => {
  //         console.log('item', item); // Logging the item
  //         return {
  //           proName: `BINANCE:${item.symbol}USDT`,
  //           title: `${item.symbol} / USD`
  //         };
  //       }),
  //       colorTheme: "light",
  //       isTransparent: false,
  //       displayMode: "regular",
  //       locale: "en",
  //     });

  //     // Append the script to the container
  //     if (containerRef.current) {
  //       containerRef.current.appendChild(script);
  //     }
  //   }
  // }, [tradeData]);

  // latest code
  // useEffect(() => {
  //   if (containerRef.current && tradeData.length > 0) {
  //     // Load TradingView library
  //     const script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.async = true;
  //     script.src = 'https://unpkg.com/lightweight-charts@3.3.0/dist/lightweight-charts.standalone.production.js';
  //     document.body.appendChild(script);

  //     script.onload = () => {
  //       if (!window.LightweightCharts) {
  //         console.error('LightweightCharts library not loaded');
  //         return;
  //       }

  //       // Initialize the chart
  //       const chart = window.LightweightCharts.createChart(containerRef.current, {
  //         width: containerRef.current.clientWidth,
  //         height: containerRef.current.clientHeight,
  //         layout: {
  //           backgroundColor: '#ffffff',
  //           textColor: '#000000',
  //         },
  //       });

  //       // Prepare the data for the chart
  //       const seriesData = tradeData.map(item => ({
  //         time: new Date(item.timestamp).getTime() / 1000, // Ensure this is in UNIX timestamp format (seconds)
  //         open: item.open_price,
  //         high: Math.max(item.open_price, item.close_price),
  //         low: Math.min(item.open_price, item.close_price),
  //         close: item.close_price,
  //       }));

  //       console.log('Series Data:', seriesData); // Debugging line

  //       // Create the candlestick series
  //       const candlestickSeries = chart.addCandlestickSeries({
  //         color: 'blue',
  //         borderColor: 'blue',
  //         wickColor: 'blue',
  //       });

  //       // Set the data
  //       candlestickSeries.setData(seriesData);

  //       // Resize chart when container size changes
  //       const handleResize = () => {
  //         chart.resize(containerRef.current.clientWidth, containerRef.current.clientHeight);
  //       };

  //       window.addEventListener('resize', handleResize);

  //       // Clean up
  //       return () => {
  //         if (containerRef.current) {
  //           containerRef.current.innerHTML = '';
  //         }
  //         document.body.removeChild(script);
  //         window.removeEventListener('resize', handleResize);
  //       };
  //     };

  //     script.onerror = () => {
  //       console.error('Failed to load LightweightCharts library');
  //     };
  //   }
  // }, [tradeData]);
  // console.log('allUserData?.totalIncome2', allUserData?.totalIncome2);
  return (
    <>
      <main>
        <div>
          <div className="coustom_container">
            <div className="main_inner_sec ">

              <div className='table_responsive'>
                {/* <div className="tradingview-widget-container">
                  <div className="tradingview-widget-container__widget" ref={containerRef}></div>
                  <div className="chart-container" ref={containerRef} style={{ width: '100%', height: '500px' }}></div> 
                </div> */}

                {/* <div className="tradingview-widget-container">
                  <div ref={containerRef} className="tradingview-widget-container__widget"></div>
                <div className='chart-container d-flex align-items-center justify-content-between'>
                  {tradeData.map((item, index) => (
                    <div key={index}>
                      <h3>Symbol: {item.symbol}</h3>
                      <p>Market Type: {item.market_trade_type}</p>
                      <p>Open Price: {item.open_price}</p>
                      <p>Close Price: {item.close_price}</p>
                      <p>Profit/Loss: {item.profit_loss}</p>
                    </div>
                  ))}
                  </div>
                </div> */}
              </div>

              <div className="header-left">
                <div className="link-copy ">
                  <input type='text' value={`${website}register/${sponsorId}`} disabled></input>
                  <div className="icon-copy">
                    <FileCopyIcon onClick={() => { copyToClipboard(`${website}register/${sponsorId}`) }} />
                  </div>
                </div>
                <div className="icons_im">
                  <img src={scan_code} className="scan_code" alt="scan" onClick={onOpenModal} />


                  <Modal open={open} onClose={onCloseModal} center classNames={{
                    modal: "qr_scan_modal",
                  }}>
                    <QRCode className="scan_modal" value={`${website}register/${sponsorId}`} />
                  </Modal>

                </div>
              </div>
              <div className='loader_main'>
                {loading &&
                  <div className="spinner_bg">

                    <div className="color_ring">  <ColorRing
                      visible={true}
                      height="80"
                      width="80"

                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                    /></div>

                  </div>
                }
                {newsData?.status &&

                  <div className="banner-section-top">


                    <div className='banner-news'>
                      <p> <VolumeUpRounded /></p>
                      <Marquee className='marquuqqq'>
                        <p><b>{t(newsData?.title)}: </b>{t(newsData?.description)}</p>
                      </Marquee>
                    </div>

                    <img src={light_banner1} className="banner-img" alt="Covido logo" />
                  </div>

                }

                {/* second section */}
                <div className='loader_main'>
                  {loading &&
                    <div className="spinner_bg">

                      <div className="color_ring">  <ColorRing
                        visible={true}
                        height="80"
                        width="80"

                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                      /></div>

                    </div>
                  }
                  <div className='cards_row'>
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'>
                              <img src={income} className="banner-img" alt="Covido logo" />
                            </div>
                            <div>
                              <h6 className="card-title">{t("Total Income")}</h6>
                              
                              <p className="card-text">{allUserData?.totalIncome2 >= 0 ? `$${Number(allUserData?.totalIncome2)?.toFixed(2)}` : "-"}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >


                          <div className="card-body">
                            <div className='card_img_outer'><img src={usd} className="banner-img" alt="Covido logo" /></div>
                            <div>
                              <h6 className="card-title">{t("Withdrawable Balance")}</h6>
                              <p className="card-text">{allUserData?.avaliableIncome >= "0" ? `$${Number(allUserData?.avaliableIncome)?.toFixed(2)}` : '-'}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'>  <img src={profit2} className="banner-img" alt="Covido logo" /></div>

                            <div>
                              <h6 className="card-title">{t("Total Deposit")}</h6>
                              <p className="card-text">{allUserData?.TotalDeposit >= "0" ? `$${Number(allUserData?.TotalDeposit)?.toFixed(2)}` : "-"}</p>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">{t("Avaliable Topup Fund")}</h6>
                              <p className="card-text">{allUserData?.AvaliableDeposit?.fundValue >= "0" ? `$${Number(allUserData?.AvaliableDeposit?.fundValue)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div>


                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">{t("Rebuy Fund")}</h6>
                              <p className="card-text">{allUserData?.AvaliableDeposit?.reBuyValue >= "0" ? `$${Number(allUserData?.AvaliableDeposit?.reBuyValue)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">{t("Direct Profit")}</h6>
                              <p className="card-text">{allUserData?.directIncome >= "0" ? `$${Number(allUserData?.directIncome)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">{t("Layered Investment Returns")}</h6>
                              <p className="card-text">{allUserData?.levelIncome >= "0" ? `$${Number(allUserData?.levelIncome)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">{t("Profit on Investment")}</h6>
                              <p className="card-text">{(allUserData?.roiIncome >= "0") ? `$${Number(allUserData?.roiIncome)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">{t("Royalty Profit")}</h6>
                              <p className="card-text">{allUserData?.royaltyIncome >= "0" ? `$${Number(allUserData?.royaltyIncome)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">{t("Eternal Global Pool Profit")}</h6>
                              <p className="card-text">{allUserData?.poolIncome >= "0" ? `$${Number(allUserData?.poolIncome)?.toFixed(2)}` : "-"}</p></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card card_inner" >

                          <div className="card-body">
                            <div className='card_img_outer'> <img src={wallet} className="banner-img" alt="Covido logo" /></div>
                            <div><h6 className="card-title">{t("Booster Profit")}</h6>
                              <p className="card-text">{allUserData?.boosterIncome >= 0 ? `$${Number(allUserData?.boosterIncome)?.toFixed(2)}` : "0.00"}</p></div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                {/* Rank Royality */}
                <div className="rank_royality">
                  <div className="row">

                    <div className="col-lg-5 ">
                      <h4>
                        {allUserData?.userInfo?.user_info?.name}
                      </h4>
                      <div className="card rank_card mb-3">
                        <div className="card-body">
                          <div className="executive_icon">
                            <AccountCircleIcon />
                            {/* <img src={user_pf}  alt="Covido logo" /> */}
                          </div>
                          <div className="current_rank">
                            <h6 className="card-title">{t("Sponsor ID")}</h6>
                            <p className="card-text">{info?.sponsor_id}</p>
                          </div>
                        </div>
                      </div>
                      <div className="card rank_card">
                        <div className="card-body">
                          <div className="executive_icon">
                            <MonetizationOnIcon />
                            {/* <img src={dollar_image} className="banner-img" alt="Covido logo" /> */}
                          </div>
                          <div className="current_rank">
                            <h6 className="card-title">{t("Package")}</h6>
                            <p className="card-text">{allUserData?.userInfo?.user_info?.package?.total_package === "0" ? "Inactive" : `$${allUserData?.userInfo?.user_info?.package?.total_package}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="right_rank_sec">
                        <div className="row">

                          {/* <div className="card">
                            <div className="card-body"> */}

                          {/* <div className="progress_sec">
                                  <p>Completed</p>
                                  {/* <ProgressBar now={now} label={`${now}%`} /> */}
                          {/* </div> */}

                          {/* </div>
                          </div> */}

                          {/* 

                          <div className="card rank_card mb-3">
                        <div className="card-body">
                          <div className="executive_icon">
                            <AccountCircleIcon />
                            {/* <img src={user_pf}  alt="Covido logo" /> */}
                          {/* </div>
                          <div className="current_rank">
                            <h6 className="card-title"> Current Rank </h6>
                            <p className="card-text">{userRank?.rank_name}</p>
                          </div>
                        </div>
                      </div>

                      <div className="card rank_card mb-3">
                        <div className="card-body">
                          <div className="executive_icon">
                            <AccountCircleIcon />
                            {/* <img src={user_pf}  alt="Covido logo" /> */}
                          {/* </div>
                          <div className="current_rank">
                            <h6 className="card-title"> Next Rank </h6>
                            <p className="card-text">{info?.sponsor_id}</p>
                          </div>
                        </div>
                      </div>  */}




                          <div className="card">
                            <div className="card-body">
                              <div className="inner_data_values">
                                <p className='rank-head'>
                                  {t("RANK ROYALTY")}
                                </p>
                                <div className="card rank_card current_card mb-3">
                                  <div className="card-body">

                                    <div className="executive_icon">
                                      <AccountCircleIcon />
                                      {/* <img src={user_pf}  alt="Covido logo" /> */}
                                    </div>

                                    <div className="current_rank">
                                      <h6 className="card-title">{t("Current Rank")}</h6>
                                      <p className="card-text">{userRank?.rank_name}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="card rank_card current_card">
                                  <div className="card-body">
                                    <div className="executive_icon">
                                      <MonetizationOnIcon />
                                      {/* <img src={dollar_image} className="banner-img" alt="Covido logo" /> */}
                                    </div>
                                    <div className="current_rank">
                                      <h6 className="card-title">{t("Next Rank")}</h6>
                                      <p className="card-text">{nextRank} </p>

                                    </div>
                                  </div>
                                </div>

                                <div className="rank_amount">
                                  <h6>{t("Rank Royalty")}</h6>
                                  <h6>${RankRoyality}</h6>
                                </div>
                                <div><h6>{t("King Maker")}</h6></div>

                                <div className="item-sec">
                                  <p>{bussinessData?.firstleg - PreviousMaxKingMaker}</p>
                                  <p>Max {MaxKingMaker}</p>
                                </div>

                              </div>
                              <div className="inner_data_values">
                                <div><h6>{t("Leg 2")}</h6></div>
                                <div className="item-sec">
                                  <p>{bussinessData?.secondleg - PreviousMaxSecondLeg}</p>
                                  <p>Max {MaxSecondLeg}</p>
                                </div>

                              </div>
                              <div className="inner_data_values">
                                <div><h6>{t("Other")}</h6></div>
                                <div className="item-sec">
                                  <p>{bussinessData?.thirdleg - PreviousMaxOther}</p>
                                  <p>Max {MaxOther}</p>
                                </div>

                              </div>
                              <div className="rule">
                                <p><span>40:40:20</span> {t("Rule")}</p>


                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                    </div>
                  </div>
                </div>

                {/* charts */}
                {/* <div className="chart_sections">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="card mb-3">
                        <div className="card-body">
                          <Chart
                            chartType="PieChart"
                            data={data}
                            options={options}
                            width={"100%"}
                            height={"400px"}
                            className='chart_style'
                          />
                        </div></div>

                    </div>
                    <div className="col-lg-6">
                      <div className="card">
                        <div className="card-body">


                          <Chart
                            chartType="PieChart"
                            width="100%"
                            height="400px"
                            data={data1}
                            options={options1}
                          />  </div>
                      </div>

                    </div>
                  </div>
                </div> */}


                {/* profit cards  */}
                <div className="profit_cards">
                  <div className="row">
                    {/* <div className="col-lg-4">
                      <div className="card card_inner" >

                        <div className="card-body">
                          <div className="profit_head">
                            <h5 className="card-title">Profit November</h5>
                            <p className="card-text">0.00% </p>
                          </div>
                          <div className='trade_head'>
                            <h6>Total Trades:</h6><h6>0</h6>
                          </div>
                          <p className='data'></p>
                          <div className='trade_head'>
                            <h6>Winning trade</h6><h6>Avg.Profit / trade</h6>
                          </div>

                          <div className='trade_head'>
                            <h6><NorthIcon className='arrow_up' /><span className='amount'>0</span>00.00%</h6><h6>0.00%</h6>
                          </div>
                          <div className='trade_head'>
                            <h6>Losing trade</h6><h6>W / L Ratio</h6>
                          </div>
                          <div className='trade_head'>
                            <h6><SouthIcon className='arrow_down' /><span className='amount_new'>0</span>00.00%</h6><h6>0.00%</h6>
                          </div>

                        </div>

                      </div>
                    </div> */}

                    <div className="col-12">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="card profit_inner_card card_1" >

                            <div className="card-body">
                              <div className="icon_profit">
                                <img src={profit3} className="profit" alt="Covido logo" />
                              </div>
                              <div className="card_data">
                                <h5 className="card-title">{t("Profit")}</h5>
                                <p className="card-text">{t("Since inception")}</p>
                                <h6>${currentMonthProfit?.toFixed(4)}</h6>
                              </div>


                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="card profit_inner_card card_2" >

                            <div className="card-body">
                              <div className="icon_profit">
                                <img src={profit5} className="profit" alt="Covido logo" />
                              </div>
                              <div className="card_data">
                                <h5 className="card-title">{t("Profit")}</h5>
                                <p className="card-text">{t("Last Month")} ({dateformat(prev, 'mmmm')})</p>
                                <h6>${lastMonthProfit}</h6></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="card profit_inner_card card_3" >

                            <div className="card-body">
                              <div className="icon_profit">
                                <img src={hour24} className="profit" alt="Covido logo" />
                              </div>
                              <div className="card_data">
                                <h5 className="card-title">{t("Profit")}</h5>
                                <p className="card-text">24 Hour</p>
                                <h6>${yesterdayProfit}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="card profit_inner_card card_4" >

                            <div className="card-body">
                              <div className="icon_profit">
                                <img src={profit4} className="profit" alt="Covido logo" />
                              </div>
                              <div className="card_data">
                                <h5 className="card-title">{t("Profit")}</h5>
                                <p className="card-text">Currently</p>
                                <h6>${todayProfit}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="card total_limit">
                            <div className="card-body">
                              <h6>{t("Total limit")} : <span>${Number(allUserData?.userInfo?.user_info?.income_limit)?.toFixed(2)}</span></h6>


                              <div className='new-bar'>
                                <div id="slider_thumb" className="range-slider_thumb"> {allUserData?.totalIncome >= "0" ? `${Number(allUserData?.totalIncome).toFixed(2)}` : "0"}</div>
                                <div className='bar-out'>

                                  <input type="range" id="vol" name="vol" min="0" value={allUserData?.totalIncome >= "0" ? `${Number(allUserData?.totalIncome).toFixed(2)}` : "0"} max={allUserData?.userInfo?.user_info?.income_limit} />
                                  {/* <input type="range" id="vol" name="vol" min="0" value="100" max="200" /> */}
                                </div>

                              </div>


                              {/* <ProgressBar now={now} label={`${now}%`} /> */}

                              {/* <p >{allUserData?.totalIncome >= "0" ? `$${Number(allUserData?.totalIncome)?.toFixed(2)}` : "0"}</p> */}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                {/* history */}
                {/* <div className="performance_cards">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="card left_card mb-3">
                        <div className="card-body">

                          <h5>Past Performance</h5>
                          <div className="list_sec">
                            <p>June, 2023</p>
                            <p className='percentage'>0.00%</p>
                            <p><FileCopyIcon /></p>
                          </div>
                          <div className="list_sec">
                            <p>July, 2023</p>
                            <p className='percentage'>0.00%</p>
                            <p><FileCopyIcon /></p>
                          </div>
                          <div className="list_sec">
                            <p>Aug, 2023</p>
                            <p className='percentage'>0.00%</p>
                            <p><FileCopyIcon /></p>
                          </div>



                        </div>
                      </div>
                    </div>
                    
                    <div className="col-lg-6">
                      <div className="card right_sec mb-3">
                        <div className="card-body">
                          <h5>History - October</h5>
                          <div className="profit_month">
                            <p>Profit October</p>
                            <p>0.00%</p>
                          </div>
                          <div className="inner_img_sec">
                            <div className="img_icons">
                              <img src={btc_img} alt="btc" />
                              <p>BTC</p>
                            </div>
                            <div className="img_icons">
                              <img src={eth} alt="eth" />
                              <p>ETH</p>
                            </div>
                            <div className="img_icons">
                              <img src={bnb_img} alt="bnb" />
                              <p>BNB</p>
                            </div>
                            <div className="img_icons">
                              <img src={xrp} alt="xrp" />
                              <p>XRP</p>
                            </div>
                          </div>
                          <button className='button_style'>Report</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div> */}

                {/* <div className="tradingview-widget-container">
                  <div className="tradingview-widget-container__widget"></div>
                  <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
                  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js" async>
                    {
                      "width": "500",
                    "height": "1200",
                    "currencies": [
                    "EUR",
                    "USD",
                    "JPY",
                    "GBP",
                    "CHF",
                    "AUD",
                    "CAD",
                    "NZD"
                    ],
                    "isTransparent": false,
                    "colorTheme": "light",
                    "locale": "en",
                    "backgroundColor": "#ffffff"
}
                  </script>
                </div> */}

                {/* table section */}
                <div className="table_section">
                  <div className="scrolling_table">
                    <div className="scroll_under">
                      <Table className="support_table">
                        <thead>
                          <tr className="table_heading_row">
                            <th className="table_heading"> #</th>
                            <th className="table_heading"> {t("Amount")}</th>
                            <th className="table_heading">{t("Scan")}</th>
                            <th className="table_heading">{t("Date")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fundValues.length == 0 ? <tr>
                            <td colSpan={4} style={{ textAlign: 'center' }}>{t("Data not found")}!</td>
                          </tr> :
                            <>
                              {fundValues?.map((item, index) => {
                                return (
                                  <>
                                    <tr className="table_data_row">
                                      <td className="table_data">{index + 1}</td>
                                      <td className="table_data">${item?.amount?.toFixed(2)}</td>
                                      <td className="table_data payment_fee">
                                        <Link to={item?.blockscan_url} className="button_style" target="_blank"> View </Link>
                                      </td>
                                      <td className="table_data payment_fee">{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                                    </tr>
                                  </>
                                )
                              })}
                            </>}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

                {/* Trading report */}
                <div className="table_section">
                  <div className="scrolling_table">
                    <div className="scroll_under">
                      <Table className="support_table">
                        <thead>
                          <tr className="table_heading_row">
                            <th className="table_heading"> {t("Symbol")}</th>
                            <th className="table_heading">{t("Buy Or Sell")}</th>
                            <th className="table_heading">{t("Open Price")}</th>
                            <th className="table_heading">{t("Close Price")}</th>
                            <th className="table_heading">{t("Profit Or Loss")}</th>
                            <th className="table_heading">{t("Date")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tradeData.length == 0 ? <tr>
                            <td colSpan={4} style={{ textAlign: 'center' }}>{t("Data not found")}!</td>
                          </tr> :
                            <>
                              {tradeData?.map((item, index) => {
                                return (
                                  <>
                                    <tr className="table_data_row">
                                      <td className="table_data">{item?.symbol}</td>
                                      <td
                                        className="table_data"
                                        style={{ color: item?.market_trade_type === 'sell' ? 'red' : 'green' }}
                                      >
                                        {item?.market_trade_type
                                          ? item.market_trade_type.charAt(0).toUpperCase() + item.market_trade_type.slice(1)
                                          : ''}
                                      </td>
                                      <td className="table_data">${item?.open_price?.toFixed(2)}</td>
                                      <td className="table_data">${item?.close_price?.toFixed(2)}</td>
                                      {/* <td style={{ color: item?.profit_loss > 0 ? "green" : "red" }}>
                                        {item?.profit_loss !== undefined ? `${item.profit_loss.toFixed(2)}%` : ""}
                                      </td> */}
                                      <td style={{ color: item?.profit_loss > 0 ? "green" : "red" }}>
                                        {item?.profit_loss !== undefined ? `${Math.abs(item.profit_loss).toFixed(2)}%` : ""}
                                      </td>
                                      <td className="table_data payment_fee">{dateformat(item?.createdAt, "UTC:dd-mm-yyyy HH:mm:ss")}</td>
                                    </tr>
                                  </>
                                )
                              })}
                            </>}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </main>

      {open3 && (
        // {bannerData?.banner_status === true ? (
        <Modal open={open3} onClose={closeModal3} center
          classNames={{
            modal: "homepage_modal",
          }}>
          <div className="banner_modal">
            <img src={bannerData?.banner} alt={bannerData?.description} className='banner_img' />
            <h6 className='modal-heading'>{bannerData?.description}</h6>
          </div>
        </Modal>
        //  ) : (
        //   null // You can replace this with any other content or leave it as an empty string
        // )}
      )}

    </>
  )

}

export default Dashboard