import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Replay } from "@mui/icons-material";
import _fetch from "../../config/api";
import { api_url } from "../../config/Config";

const Overview = (props: any) => {
  

  useEffect(() => {
    document.title = `${props.pageTitle}`;
  },[]);
  return (
    <>
      <main>
        <div className="uni-level-outer">
          <div className="coustom_container">
            <div className="level_inner loader_main">

              <div className="top_section_unilevel">
                <div className="form-left-overview">
                  <div className="date">
                    <span className='from'>From</span>   <input type='date' className="form-control" placeholder='' />
                  </div>

                  <div className="date">
                    <span className='to'>To</span>   <input type='date' className="form-control" placeholder='' />
                  </div>
                  <div className='date'>
                    <span className='select_level'>Level</span>
                    <select className="form-control select_option" >
                      <option value="">All</option>
                      <option value="0">Level</option>
                      <option value="1">All</option>
                      <option value="2">Level 1</option>
                      <option value="3">Level 2</option>
                      <option value="4">Level 3</option>
                    </select>
                  </div>

                  <p className='level_open'>Levels Open <span> 0</span></p>

                  <div className="form-btn">

                    {/* <button className="searchicons">
                                    <SearchIcon />
                                </button> */}
                    <button className="searchicons">
                      <Replay />
                    </button>
                  </div>
                </div>


                <div className="form-right-overview">
                  <p>Level Bonus Earned</p>
                  <p>0.00</p>
                </div>
              </div>
              <div className="table_section">
                <div className="scrolling_table">
                  <div className="scroll_under">
                    <Table className="support_table">
                      <thead>
                        <tr className="table_heading_row">
                          <th className="table_heading">Date</th>
                          <th className="table_heading">Description	</th>
                          <th className="table_heading">Country</th>
                          <th className="table_heading">Level</th>
                          <th className="table_heading">Generated	</th>
                          <th className="table_heading">Credited</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr className="table_data_row">
                          <td className="table_data">$ 10</td>
                          <td className="table_data">
                            <div className="table_data_description">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem reiciendis, reprehenderit dicta, optio error eaque et quo mollitia.
                            </div>
                          </td>
                          <td className="table_data">
                            <div className="pic_text"> India</div> </td>
                          <td className="table_data"> 1</td>
                          <td className="table_data"> Generated</td>
                          <td className="table_data"> Credited</td>

                        </tr>

                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Overview;
