import React, { useEffect, useState } from "react";
import { api_url, titleDescription } from "../../config/Config";
import { Row, Col, Accordion } from "react-bootstrap";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import EmailIcon from "@mui/icons-material/Email";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Table from "react-bootstrap/Table";
import ReplayIcon from "@mui/icons-material/Replay";
import _fetch from "../../config/api";
import ReactPaginate from "react-paginate";
import { ColorRing } from "react-loader-spinner";
// import userActive from "../../Assets/Image/userIconactive.png"
// import userInactive from "../../Assets/Image/userIconinactive.png"
// import { Info } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

const Team = (props: any) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });

  const [sponsorData, setSponsorData] = useState({ email: "", user_info: { name: "" } });
  const [levelSearch, setLevelSearch] = useState('');
  const [downloadID, setDownloadID] = useState('');
  const [allUsersDetail, setAllUsersDetail] = useState([]);
  const [searchedType, setSearchType] = useState('level');
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allLevels, setAllLevels] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
  ]);

  const [keyCount, setKeycount] = useState(0);

  useEffect(() => {
    getSponsorDetails();
  }, []);

  useEffect(() => {
    getAllTeamData();
  }, [levelSearch, downloadID, page, limit]);

  const getSponsorDetails = async () => {
    let data = await _fetch(`${api_url}sponsor/sponsordetails`, 'GET', {})
    if (data?.status === 'success') {
      setSponsorData(data?.data[0]);
    }
  }

  const getAllTeamData = async () => {
    setLoading(true);
    if (levelSearch != "" || downloadID != "") {
      var searchData = `&search=${searchedType}&value=${levelSearch}&downline_id=${downloadID}`;
    } else {
      var searchData = "";
    }
    let data = await _fetch(`${api_url}sponsor/getteam?&page=${page}&limit=${limit}${searchData}`, 'GET', {})
    if (data?.status === 'success') {
      setLoading(false);
      setAllUsersDetail(data?.data);
      setTotalRecords(data?.total);
    }
    if (page > 1) {
      setKeycount(0);
      var count = page - 1;
      setKeycount(count * limit);
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };

  const refresh = () => window.location.reload();


  const toggleClass = (id: any) => {
    const element = document.getElementById(id);
    if (element) {
      element.classList.toggle('show__details');
    }
  };


  return (
    <main>
      <div className="team-section">
        <div className="coustom_container">
          <div className="team-outer  loader_main">
            {loading &&
              <div className="spinner_bg">

                <div className="color_ring">  <ColorRing
                  visible={true}
                  height="80"
                  width="80"

                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                /></div>

              </div>
            }

            <Tabs>
              <Row>
                <Col xl={12} >
                  <div className="team-inner">
                    <Row className="team_row">
                      <Col xl={3} md={4} lg={4} className="p-0" >
                        <div className="team-sponser">
                          <div className="sponser-head">
                            <h6 className="mb-2">{t("MY SPONSOR")}</h6>
                          </div>
                          <div className="sponser-content">
                            <p>
                              <AccountCircleRoundedIcon />{sponsorData?.user_info?.name?.toUpperCase()}
                            </p>
                            <p>
                              <EmailIcon />
                              {sponsorData?.email}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col xl={9} md={8} lg={8} className="team_col p-0">
                        <div className="team-head-content">
                          <div className="team-search">
                            <form className="serch-form">
                              <input
                                type="text"
                                id="names"
                                placeholder="Search by username" onChange={(e: any) => setDownloadID(e.target.value)}

                              ></input>
                              <select className="form-control level" onChange={(e: any) => setLevelSearch(e.target.value)} value={levelSearch}>
                                <option value="">All</option>
                                {allLevels?.map((item: any, key: any) => (
                                  <option key={key} value={item}> {'Level' + " " + item} </option>
                                ))}
                              </select>
                            </form>
                            <div className="form-btn">
                              <button className="searchicons" onClick={refresh}>
                                <ReplayIcon />
                              </button>
                            </div>
                          </div>
                          <div className="team-tabs">
                            <TabList>
                              <Tab>{t("TABLE VIEW")}</Tab>
                              <Tab>{t("TREE VIEW")}</Tab>
                            </TabList>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="panel">
                      <TabPanel>
                        <div className="table_section">
                          <div className="scrolling_table">
                            <div className="scroll_under">
                              <Table className="support_table">
                                <thead>
                                  <tr className="table_heading_row">
                                    <th className="table_heading">#</th>
                                    <th className="table_heading">{t("Username")}</th>
                                    <th className="table_heading">{t("Name")}</th>
                                    <th className="table_heading">{t("Email")}</th>
                                    <th className="table_heading">{t("Level")}</th>
                                    <th className="table_heading">{t("Country")}</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  {allUsersDetail?.map((item: any, index: any) => {


                                    return (
                                      <>
                                        <tr className="table_data_row">
                                          <td className="table_data">
                                            <div className="pic_text"> {page > 1 ? index + keyCount + 1 : index + 1} </div>
                                          </td>
                                          <td className="table_data">{item?.downlineId}</td>

                                          <td className="table_data">{item?.downlineDetails?.user_info?.name}</td>
                                          <td className="table_data">{item?.downlineDetails?.email}</td>
                                          <td className="table_data payment_fee">
                                            {item?.levelNumber}
                                          </td>

                                          <td className="table_data payment_fee">
                                            <img src={item?.downlineDetails?.user_info?.country_flag} style={{ width: "20px" }} />
                                            <span className="ms-2">{item?.downlineDetails?.user_info?.country}</span>
                                          </td>
                                        </tr>

                                      </>
                                    )
                                  })}
                                </tbody>
                              </Table>

                              {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={1}
                                pageCount={totalRecords / 10}
                                previousLabel="<"
                              />}

                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card mt-5">
                              <div className="card-body">
                                <h6 >
                                  Comming soon
                                </h6>

                              </div>

                            </div>
                          </div>
                        </div>

                      </TabPanel>


                      {/* <TabPanel>

                        <div className="row team__sec">
                          <div className="col-lg-12">
                            <div className="card mt-2 main-tree">

                              <div className="heading__team_tree">
                                <h4> User Name Team Tree</h4>
                              </div>

                              <div className="card-body">

                                <ul className="tree vertical ">
                                  <li>
                                    <div className="admin">
                                      <div className="admin-img">
                                        <img className="img-sec" src={userActive} alt="active user" />
                                      </div>
                                      <p> Vista (admin)</p>
                                    </div>

                                    <ul>
                                      <li>
                                        <div id="UserA" className="user-main-sec">

                                          <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                          <div className="user">
                                            <div className="user-img">
                                              <img className="img-sec" src={userInactive} alt="userInactive" />
                                            </div>
                                          </div>

                                          <div className="user__name">
                                            <div className="user-img">
                                              <p> Vista ( User ) </p>
                                            </div>
                                          </div>

                                          <div className="cads">
                                            <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                            <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                            <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                            <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                          </div>
                                        </div>
                                        <ul>
                                          <li>
                                            <div id="UserA" className="user-main-sec">

                                              <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                              <div className="user">
                                                <div className="user-img">
                                                  <img className="img-sec" src={userInactive} alt="userInactive" />
                                                </div>
                                              </div>

                                              <div className="user__name">
                                                <div className="user-img">
                                                  <p> Vista ( UPPP ) </p>
                                                </div>
                                              </div>

                                              <div className="cads">
                                                <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div id="UserA" className="user-main-sec">

                                              <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                              <div className="user">
                                                <div className="user-img">
                                                  <img className="img-sec" src={userInactive} alt="userInactive" />
                                                </div>
                                              </div>

                                              <div className="user__name">
                                                <div className="user-img">
                                                  <p> Vista ( funddf ) </p>
                                                </div>
                                              </div>

                                              <div className="cads">
                                                <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <div id="UserA" className="user-main-sec">

                                          <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                          <div className="user">
                                            <div className="user-img">
                                              <img className="img-sec" src={userInactive} alt="userInactive" />
                                            </div>
                                          </div>

                                          <div className="user__name">
                                            <div className="user-img">
                                              <p> Vista ( User ) </p>
                                            </div>
                                          </div>

                                          <div className="cads">
                                            <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                            <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                            <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                            <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                          </div>
                                        </div>

                                        <ul>
                                          <li>
                                            <div id="UserA" className="user-main-sec">

                                              <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                              <div className="user">
                                                <div className="user-img">
                                                  <img className="img-sec" src={userInactive} alt="userInactive" />
                                                </div>
                                              </div>

                                              <div className="user__name">
                                                <div className="user-img">
                                                  <p> Vista ( User ) </p>
                                                </div>
                                              </div>

                                              <div className="cads">
                                                <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div id="UserA" className="user-main-sec">

                                              <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                              <div className="user">
                                                <div className="user-img">
                                                  <img className="img-sec" src={userInactive} alt="userInactive" />
                                                </div>
                                              </div>

                                              <div className="user__name">
                                                <div className="user-img">
                                                  <p> Vista ( User ) </p>
                                                </div>
                                              </div>

                                              <div className="cads">
                                                <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <div id="UserA" className="user-main-sec">

                                          <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                          <div className="user">
                                            <div className="user-img">
                                              <img className="img-sec" src={userInactive} alt="userInactive" />
                                            </div>
                                          </div>

                                          <div className="user__name">
                                            <div className="user-img">
                                              <p> Vista ( User ) </p>
                                            </div>
                                          </div>

                                          <div className="cads">
                                            <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                            <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                            <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                            <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                          </div>
                                        </div>
                                        <ul>
                                          <li>
                                            <div id="UserA" className="user-main-sec">

                                              <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                              <div className="user">
                                                <div className="user-img">
                                                  <img className="img-sec" src={userInactive} alt="userInactive" />
                                                </div>
                                              </div>

                                              <div className="user__name">
                                                <div className="user-img">
                                                  <p> Vista ( User ) </p>
                                                </div>
                                              </div>

                                              <div className="cads">
                                                <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                              </div>
                                            </div>
                                            <ul>
                                              <li>
                                                <div id="UserA" className="user-main-sec">

                                                  <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                                  <div className="user">
                                                    <div className="user-img">
                                                      <img className="img-sec" src={userInactive} alt="userInactive" />
                                                    </div>
                                                  </div>

                                                  <div className="user__name">
                                                    <div className="user-img">
                                                      <p> Vista ( User ) </p>
                                                    </div>
                                                  </div>

                                                  <div className="cads">
                                                    <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                    <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                    <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                    <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </li>
                                          <li>
                                            <div id="UserA" className="user-main-sec">

                                              <div className="show__details" onClick={() => toggleClass('UserA')}> <Info /></div>

                                              <div className="user">
                                                <div className="user-img">
                                                  <img className="img-sec" src={userInactive} alt="userInactive" />
                                                </div>
                                              </div>

                                              <div className="user__name">
                                                <div className="user-img">
                                                  <p> Vista ( User ) </p>
                                                </div>
                                              </div>

                                              <div className="cads">
                                                <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>

                              </div>

                            </div>
                          </div>
                        </div>

                      </TabPanel> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </Tabs>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Team;
