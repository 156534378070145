import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { api_url, titleDescription } from "../../config/Config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import _fetch from "../../config/api";
import toasted from "../../config/toast";
import { getData } from 'country-list';
import Select, { SingleValue } from 'react-select';
import Loader from "../../config/Loader";

const Register = (props: any) => {

  const [btnDisable, setbtnDisable] = useState(false);


  const { sponser } = useParams();
  const Navigate = useNavigate();

  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });

  const [refferalID, setRefferalID] = useState("");
  // const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswords, setShowPasswords] = useState(false);
  const [selectCheckbox, setSelectCheckbox] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(null);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleTogglePasswords = () => {
    setShowPasswords(!showPasswords);
  };

  useEffect(() => {
    if (sponser != null) {
      setRefferalID(sponser);
    }
  }, []);

  type CountryOption = {
    value: string;
    label: string;
    flagUrl: string;
  };

  const CustomOption: React.FC<{ innerProps: any; label: string; data: CountryOption }> = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <img src={data.flagUrl} alt={label} style={{ marginRight: '8px' }} />
      {label}
    </div>
  );

  const countryOptions: CountryOption[] = getData().map((country) => ({
    value: country.code,
    label: country.name,
    flagUrl: `https://flagcdn.com/16x12/${country.code.toLowerCase()}.png`
  }));

  const submitRegister = async (e: any) => {
    setbtnDisable(true)
    e.preventDefault();

    let data = {
      "sponsor_id": refferalID,
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "country": selectedCountry?.label,
      "country_flag": selectedCountry?.flagUrl,

      "password": password,
      "confirm_password": confirmPassword
    }

    let res = await _fetch(`${api_url}auth/signup`, "POST", data, {})
    if (selectCheckbox) {
      if (res?.status === "success") {
        // localStorage.setItem("auth", 'true');
        // localStorage.setItem("accessToken", res?.token);
        // localStorage.setItem("user", JSON.stringify(res?.data?.user));
        // toasted.success(res?.message);
        Navigate("/waiting-conformation",{state: firstName+' '+lastName});  

        setbtnDisable(false)
      }
      else {
        toasted.error(res?.message);
        setbtnDisable(false)
      }
    }
    else {
      toasted.error("Please select checkbox");
    }
  }

  return (
    <div className="login-body register-pg">
      {/* <div className="waves"> */}
      <div className="empty">
        <div className="coustom_container">
          <Row className="form-row">
            {/* <Col xl={6} lg={5} md={5} className="left-sec">
              <div className="login-pics">
                <img src={loginimg55} alt="loginimg" />
              </div>
            </Col> */}
            <Col xl={12} lg={12} md={12} className="login-col">
              <div className="waves">
                <div className="form-outer exchange">
                  <div className="form-inner">
                    <div className="form-head">
                      <h2>Register</h2>
                      <p>Create an account</p>
                    </div>
                    <form className="form-start">
                      <input type="text" id="email" placeholder="Refferal ID" onChange={(e) => setRefferalID(e.target.value)} value={refferalID} />
                      <input type="text" id="email" placeholder="First Name" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                      <input type="text" id="email" placeholder="Last Name" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                      <input
                        type="text"
                        id="email"
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />

                      <Select
                        options={countryOptions}
                        value={selectedCountry}
                        onChange={(option: SingleValue<CountryOption>) => setSelectedCountry(option)}
                        components={{ Option: CustomOption }}
                        isSearchable
                        placeholder="Select a country..."
                      />

                      <div className="hide-pass">
                        <input
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          id="email"
                          placeholder="Password"
                        />

                        <button type="button" onClick={handleTogglePassword}>
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </button>
                      </div>
                      <div className="hide-pass">
                        <input
                          type={showPasswords ? "text" : "password"}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          id="email"
                          placeholder="Confirm Password"
                        />

                        <button type="button" onClick={handleTogglePasswords}>
                          {showPasswords ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </button>
                      </div>
                      <div className="login-remfor">
                        <div className="login-check">
                          <input type="checkbox" id="rem-form" onChange={(e: any) => setSelectCheckbox(e.target.checked)} />
                          <label className="me-label" htmlFor="rem-form"> I agree to the terms of service </label>
                        </div>
                      </div>
                      <button type="button" className="log-btn" onClick={submitRegister} disabled={btnDisable}>
                      
                      {btnDisable === true &&
                          <Loader LoaderTxt={'Please Wait...'} />
                        }
                        {btnDisable === false && 'Register '}
                      </button>
                      <div className="form-para">
                        <p>
                          Already have an account? <Link to="/login">Login here</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Register;
