import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { api_url, titleDescription } from '../../config/Config';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import { ColorRing } from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import { Link, useNavigate } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';
// import { exit } from 'process';
import { useTranslation } from 'react-i18next';
import Loader from '../../config/Loader';

const PayoutRequest = (props: any) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });

    const [balance, setBalance] = useState(0);
    const [amount, setamount] = useState(0);

    const [deduction, setDeduction] = useState(0);
    const [walletAddresses, setWalletAddresses] = useState({ BEP20: "", TRC20: "" });
    const [activeTab, setActiveTab] = useState(0);
    const [selectedIncomeRadio, setSelectedIncomeRadio] = useState(null);
    const [selectedWalletRadio, setSelectedWalletRadio] = useState('BEP20');
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [otp, setotp] = useState();
    const [btnDisable, setbtnDisable] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    useEffect(() => {
        geTotalIncome();
        getUserInfo();

    }, []);

    const geTotalIncome = async () => {
        setLoading(true);
        let res = await _fetch(`${api_url}withdraw/check-balance`, "GET", {})
        if (res?.status === 'success') {
            setLoading(false);
            setBalance(res?.balance);
            setDeduction(res?.deduction);
            // setamount(res?.balance);
        }

        let data = await _fetch(`${api_url}withdraw/check-wallet-addresses`, "GET", {})
        if (data?.status === "success") {
            setLoading(false);
            setWalletAddresses(data?.data);
        }
    }

    const handleIncomeRadioChange = (value: any) => {
        setSelectedIncomeRadio(value);
    };

    const handleSecondTabRadioChange = (value: any) => {
        setSelectedWalletRadio(value);
    };

    const handleNext = () => {
        if (balance > 0) {
            // console.log('activeTab', activeTab);

            if (activeTab == 1 && SecurityType == 'none') {
                toasted.error("Please activate 2fa ");
                return;
            }
            // if (activeTab === 1 && !selectedWalletRadio) {
            //     toasted.error("Please select atleast one value");
            //     return;
            // }
            setActiveTab((prevTab) => prevTab + 1);
        }
        else {
            toasted.error("Insufficient balance to proceed ")
        }
        // console.log("!walletAddresses", !walletAddresses);

        if (walletAddresses?.BEP20 === null) {
            setOpen(true);
        }
    };


    const withdraw = async () => {
        setbtnDisable(true)
        let data = {
            "amount": amount,
            "type": selectedWalletRadio,
            'otp': otp
        }
        let res = await _fetch(`${api_url}withdraw/withdrawal`, "POST", data, {})
        if (res?.status === 'success') {
            toasted.success(res?.message);
            setActiveTab(0);
            geTotalIncome();
            setbtnDisable(false)

        }
        else {
            setbtnDisable(false)
            toasted.error(res?.message);
        }
    }

    const closeModal = () => {
        setOpen(true);
    };

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/profile', { state: { selectedTab: 'Wallet' } });
    };

    const [isButtonClicked, setIsButtonClicked] = useState(false);


    const flipCard = () => {
        setIsButtonClicked(true);

    };
    const [SecurityType, setSecurityType] = useState('none');

    const [userInfo, setUserInfo] = useState({ 'phone': '', 'security_type': '', 'email': '' });
    async function getUserInfo() {
        var d = await _fetch(`${api_url}user/userInfo`, 'GET', {}, {});
        if (d?.status === 'success') {
            setUserInfo(d?.data?.data);

            setSecurityType(d?.data?.data?.security_type);


        }
    }


    return (
        <>
            <main>
                <div className="request_main">
                    <div className="coustom_container">
                        <div className="request_inner loader_main">
                            <h4>{t("PAYOUT REQUEST")}</h4>

                            <div className={isButtonClicked ? 'info_alert show__alert' : 'info_alert'}>
                                <div className="alert alert-info" role="alert">
                                    <p className='mb-0'> <InfoOutlined /> {t("It may take 24hours for withdraw approval")}!  </p>
                                </div>
                            </div>
                            {loading &&
                                <div className="spinner_bg">

                                    <div className="color_ring">  <ColorRing
                                        visible={true}
                                        height="80"
                                        width="80"

                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                        colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                                    /></div>

                                </div>
                            }

                            <Tabs selectedIndex={activeTab} >
                                <TabList className="tablist_sec">
                                    <Tab >

                                        <div className="number">
                                            <span className='number_style_1'> 1</span>
                                        </div>
                                        <div className="text_head">{t("Choose Withdrawal Funds")}</div>
                                    </Tab>

                                    <Tab>
                                        <div className="number">
                                            <span className='number_style'> 2</span>
                                        </div>
                                        <div className="text_head">{t("Choose Your Wallet")}</div>
                                    </Tab>
                                    <Tab>
                                        <div className="number">
                                            <span className='number_style'> 3</span>
                                        </div>
                                        <div className="text_head">{t("Confirm Payout Information")}</div>
                                    </Tab>


                                </TabList>



                                <TabPanel>
                                    <div className="panel_section">
                                        <h6 className='block_head'>{t("Choose Your Wallet")}</h6>
                                        <div className="card income_card mb-4">

                                            <label htmlFor="totalIncome">

                                                <div className="card-body">
                                                    <div className='left-sec'>
                                                        <MonetizationOnIcon />
                                                        <div className="total_income">

                                                            <h6 className='total'>
                                                                {t("Total Income")}
                                                            </h6>
                                                            {/* <h6 className='info'>${balance}</h6> */}
                                                            <h6 className='info'>${balance?.toFixed(4)}</h6>


                                                        </div>
                                                    </div>

                                                    {/* <input type="radio" name="Subject" value="selected" onChange={(e: any) => setIncomeCheck(e)} /> */}
                                                    <input type="radio" name="Subject" id="totalIncome" value="option1" onChange={() => handleIncomeRadioChange('option1')} checked />
                                                </div>
                                            </label>
                                        </div>



                                    </div>
                                    <div className="button_sec">
                                        <a className="button_style mt-3" onClick={handleNext} >{t("Next")}< ArrowForwardIosIcon /></a>
                                    </div>

                                </TabPanel>
                                <TabPanel>
                                    <div className="panel_section">
                                        <h6 className='block_head'>{t("Confirm Payout Information")}</h6>
                                        <div className="card income_card mb-4">
                                            <label htmlFor="BEP20_address">

                                                <div className="card-body">
                                                    <div className='left-sec'>
                                                        <AccountBalanceWalletIcon className='p-2' />
                                                        <div className="total_income">

                                                            <h6 className='total'>
                                                                {t("Wallet Type")}
                                                            </h6>
                                                            <h6 className='info'> BEP20: {walletAddresses?.BEP20}</h6>

                                                        </div>
                                                    </div>

                                                    <input type="radio" id="BEP20_address" name="Subject" value="BEP20" onChange={() => handleSecondTabRadioChange('BEP20')} checked />
                                                </div>
                                            </label>
                                        </div>

                                        {/* <div className="card income_card mb-4">

                                            <label htmlFor="TRC20_address">

                                                <div className="card-body">
                                                    <div className='left-sec'>
                                                        <AccountBalanceWalletIcon className='p-2' />
                                                        <div className="total_income">

                                                            <h6 className='total'>
                                                                Wallet Type
                                                            </h6>
                                                            <h6 className='info'>TRC20 {walletAddresses?.TRC20} </h6>

                                                        </div>
                                                    </div>

                                                    <input type="radio" id="TRC20_address" name="Subject" value="TRC20" onChange={() => handleSecondTabRadioChange('TRC20')} />
                                                </div>
                                            </label>
                                        </div> */}

                                    </div>
                                    <div className="button_sec">
                                        {/* <a className="button_style mt-3" onClick={handleNext} >Next< ArrowForwardIosIcon /></a> */}
                                        <a className="button_style mt-3" onClick={() => { flipCard(); handleNext() }} >{t("Next")}< ArrowForwardIosIcon /></a>
                                    </div>


                                </TabPanel>
                                <TabPanel>
                                    <div className="panel_section payout_outer">
                                        <h6 className='block_head'>{t("Choose Withdrawal Funds")}</h6>
                                        <div className="panel_inner">
                                            <p>{t("Balance")}: ${balance}</p>
                                            <div className="pay-req req__sec">
                                                <h6>{t("Payout Request")}:</h6>
                                                {/* <h6 className='amount'>${balance > 0 ? balance?.toFixed(2) : "0"}</h6> */}
                                                <input type='number' className='payout-request' onChange={(e: any) => { e.target.value >= 0 ? setamount(e.target.value) : toasted.error('Please enter a valid amount!'); }} value={amount} />
                                                {/* / /// onChange={(e: any) => setamount(e.target.value)} value={amount} /> */}
                                            </div>

                                            <div className="pay-req">
                                                <h6>{t("Admin Handling Charges")}:</h6>
                                                <h6 className='amount'>${((amount / 100) * (100 * deduction))?.toFixed(4)}</h6>
                                            </div>

                                            <div className="pay-req">
                                                <h6>{t("Release Payout")}:</h6>
                                                <h6 className='amount'>${(amount / 100 * (100 - (100 * deduction)))?.toFixed(4)}</h6>
                                            </div>
                                            <div className="pay-req req__sec">
                                                <h6>{t("Auth Code")}:</h6>
                                                {/* <h6 className='amount'>${balance > 0 ? balance?.toFixed(2) : "0"}</h6> */}
                                                <input type='number' className='payout-request' onChange={(e: any) => { setotp(e.target.value) }} value={otp} />
                                                {/* / /// onChange={(e: any) => setamount(e.target.value)} value={amount} /> */}
                                            </div>
                                        </div>


                                    </div>
                                    <div className="button_sec">
                                        <button type='button' className="button_style mt-3" onClick={withdraw} disabled={btnDisable}>
                                            {btnDisable === true &&
                                                <Loader LoaderTxt={'Please Wait...'} />
                                            }
                                            {btnDisable === false && <>{t("Confirmation")} < ArrowForwardIosIcon /></>}

                                            </button>
                                    </div>

                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>


                {open && (

                    <Modal open={open} onClose={closeModal} center
                        classNames={{
                            modal: "request_modal",
                        }}>
                        <div className="request_inner">
                            <p>Please update your wallet addresses</p>
                            {/* <Link to={{ pathname: "/profile", state: {selectedTab: 'Wallet'}} as any} className='button_style text-deco-none'>Click Here</Link> */}
                            <button onClick={handleClick} className='button_style text-deco-none'>
                                Click Here
                            </button>

                        </div>
                    </Modal>
                )}
            </main>

        </>

    )

}

export default PayoutRequest