import React from 'react'
import { title } from '../config/Config'

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="row align-items-center flex-row-reverse ">
          <div className="col-md-12 col-sm-12 text-center">
            <p> Copyright © 2023- {(new Date().getFullYear())} {title}. All Rights Reserved. </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer