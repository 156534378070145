import React, { useEffect, useState } from 'react'
import { api_url, titleDescription } from '../../config/Config';
import ques from '../../Assets/Image/ques.png';
import uploadIcon from '../../Assets/Image/uploadIcon.png';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import { useTranslation } from 'react-i18next';
import Loader from '../../config/Loader';
const Support = (props: any) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });

    const [selectedOption, setSelectedOption] = useState('');
    const [description, setDescription] = useState('');
    // const [image, setImage] = useState('');
    const [imagePath, setImagePath] = useState('');
    const [uploadImage, setUploadImage] = useState('');

    const handleImageChange = (e: any) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Image = reader.result as string;;
                setUploadImage(base64Image);
            };

            reader.readAsDataURL(selectedImage);
        }
    };
    const [btnDisable, setbtnDisable] = useState(false);
    const SubmitTicket = async () => {
        setbtnDisable(true);
        // let formData = new FormData();
        let data = {
            "subject": selectedOption,
            "description": description,
            "image": uploadImage
        }

        let res = await _fetch(`${api_url}support/support-ticket`, "POST", data, {})
        if (res?.status === "success") {
            toasted.success(res?.message);
            setbtnDisable(false);

        }else{
            setbtnDisable(false);
            toasted.error(res?.message);
        }
    }

    return (
        <main>
            <div className="support_main">
                <div className="coustom_container">
                    <div className="support_inner">
                        <div className="top_head_sec">
                            <h4>{t("CREATE TICKET")}</h4>

                        </div>
                        <div className="support-section">
                            <p className="select_reason">
                                <select className="form-select" aria-label="Default select example" onChange={(e: any) => setSelectedOption(e.target.value)} value={selectedOption} >
                                    <option selected>{t("Question about node")}</option>
                                    {/* <option value="Please specify your reason">Please specify your reason</option> */}
                                    <option value="Question about income">{t("Question about income")}</option>
                                    <option value="Others">{t("Others")}</option>
                                </select>
                            </p>
                            <div className="desc">
                                <textarea rows={4} placeholder={t("Description")} className='text_sec' onChange={(e: any) => setDescription(e.target.value)} value={description} />
                            </div>
                        </div>

                        <div className="row support_row">
                            <div className="col-lg-7 col-md-6 col-sm-6">
                                <div className="right_sec_support">
                                    <p>{t("Attach upto 5 files")}<br />
                                        {t("The file size should not exceed 4 MB")}<br />
                                        {t("File format")} .png, jpeg, jpg</p>
                                    <h4>24x7 Live</h4>
                                    <img src={ques} alt="question" className='question' />
                                </div>

                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-6">
                                <div className="upload_document">

                                    <div className="main">

                                        <div className="upload-input">
                                            <p>{t("Upload your files here")}</p>
                                            <label htmlFor="upload"><span><img
                                                src={imagePath ? imagePath : uploadIcon}
                                                alt="card" /></span>
                                                <span><input type="file" id="upload" style={{ display: "none" }} onChange={(e: any) => { handleImageChange(e); setImagePath((URL.createObjectURL(e.target.files[0]))) }} /></span>
                                            </label>
                                            <label className="button_style browse" htmlFor="upload"  >{t("Browse Files")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="send_sec">
                            <button className='button_style send' onClick={SubmitTicket} disabled={btnDisable}>
                                {btnDisable === true &&
                                    <Loader LoaderTxt={'Please Wait...'} />
                                }
                                {btnDisable === false && <>{t("Send")}</>}
                            </button>
                        </div>


                    </div>
                </div>
            </div>
        </main>
    )
}

export default Support