import React, { useEffect, useState } from 'react';
import { title, titleDescription } from '../../config/Config';
import shortLogo from '../../Assets/Icon/shortLogo.png';
import appleDownload from '../../Assets/homepage/App Store.webp';
import googlePlay from '../../Assets/homepage/Google Play.png';
import wallet_sec from '../../Assets/homepage/fun-fact-thumb.png';
import crypto1 from '../../Assets/homepage/Invoicing-Software-Track-Image-1.webp';
import crypto2 from '../../Assets/homepage/Invoicing-Software-Track-Image-2.webp';
import crypto3 from '../../Assets/homepage/Invoicing-Software-Cash-Image-1.webp';
import crypto4 from '../../Assets/homepage/Invoicing-Software-Delivery-Image-1.png';
import download_phone from '../../Assets/homepage/downloadScreen.png';
import blockchin_binnace from '../../Assets/homepage/blockchain.webp';
import blockchain_eth from '../../Assets/homepage/ethblock.webp';
import exchange_binance from '../../Assets/homepage/binace_ex.webp';
import exchange_bybit from '../../Assets/homepage/bybit_ex.webp';
import exchange_coinbase from '../../Assets/homepage/coinbase_ex.webp';
import exchange_kucoin from '../../Assets/homepage/kucoin-ex.webp';
import chart_sec from '../../Assets/homepage/app-mobile-image-3.png';
import banner_overly from '../../Assets/homepage/gic_dash.png';

// all coins imges...
import polkadot from '../../Assets/homepage/coin_img/sym-dot_colored.svg';
import solana from '../../Assets/homepage/coin_img/sym-sol_colored.svg';
import ripple from '../../Assets/homepage/coin_img/sym-xrp_colored.svg';
import USD_tether from '../../Assets/homepage/coin_img/sym-usdt_colored.svg';
import cardano from '../../Assets/homepage/coin_img/sym-ada_colored.svg';
import ethereum from '../../Assets/homepage/coin_img/sym-eth_colored.svg';
import bitcoin from '../../Assets/homepage/coin_img/sym-btc_colored.svg';
import uniswap from '../../Assets/homepage/coin_img/sym-uni_colored.svg';
import usd_coin from '../../Assets/homepage/coin_img/sym-usdc_colored.svg';
import Filecoin from '../../Assets/homepage/coin_img/sym-fil_colored.svg';
import Polygon from '../../Assets/homepage/coin_img/sym-matic_colored.svg';
import Monero from '../../Assets/homepage/coin_img/sym-xmr_colored.svg';
import Wrapped_Bitcoin from '../../Assets/homepage/coin_img/sym-wbtc_colored.svg';
import Algorand from '../../Assets/homepage/coin_img/sym-algo_colored.svg';
import Chainlink from '../../Assets/homepage/coin_img/sym-link_colored.svg';
import bitcoin_cash from '../../Assets/homepage/coin_img/sym-bch_colored.svg';
import Litecoin from '../../Assets/homepage/coin_img/sym-ltc_colored.svg';
import Lumen from '../../Assets/homepage/coin_img/sym-xlm_colored.svg';


import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Person2, Security, StackedBarChart } from '@mui/icons-material';
// import { Modal } from 'react-responsive-modal';



const Index = (props: any) => {
  // const [open, setOpen] = useState(false);

  // const onOpenModal = () => setOpen(true);
  // const onCloseModal = () => setOpen(false);

  useEffect(() => {
    document.title = titleDescription;
  });

  const [topObjectStyle, setTopObjectStyle] = useState<React.CSSProperties>({
    top: 0,
  });



  useEffect(() => {



    const handleScroll = () => {

      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;


      const scrollPercentage = (scrollY / windowHeight) * 100;

      setTopObjectStyle({
        // background: 'blue',
        top: `-${scrollY * 1.3}px`,
      });


      if (scrollPercentage >= 10) {

        setTopObjectStyle({
          top: `-${scrollY * 1.3}px`,
          opacity: '.4'

        });

      };

      if (scrollPercentage >= 50) {

        setTopObjectStyle({
          top: `-${scrollY * 1.3}px`,
          opacity: '0'

        });

      };

      if (scrollPercentage >= 80) {

        setTopObjectStyle({
          display: 'none'

        });

      };

    };


    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, [setTopObjectStyle]);



  // useEffect(() => {
  //   const handleWindowLoad = () => {
  //     setOpen(true);
  //   };

  //   window.addEventListener('load', handleWindowLoad);


  //   return () => {
  //     window.removeEventListener('load', handleWindowLoad);
  //   };
  // }, []);

  // const closeModal = () => {
  //   setOpen(false);
  // };

  return (
    <>
      <div>
        <section className='section-index section-banner'>


          {/* <span className='animation-span coin-right-1' style={topObjectStyle}></span>
          <span className='animation-span coin-right-2' style={topObjectStyle} ></span>
          <span className='animation-span coin-right-3' style={topObjectStyle}></span> */}


          <div className='container-banner'>

            <div className='banner-top-continer'>
              <div className='web-logo'>
                <img src={shortLogo} alt="logo" />
              </div>
              <h1 className='font-family-heading'>{title}</h1>
              <p>Welcome to {title}, where we merge innovation with opportunity in the world of cryptocurrency. Our platform is designed to make cryptocurrency earning accessible, efficient, and secure. Whether you're a seasoned investor or just starting, {title} is your trusted partner in navigating the dynamic landscape of crypto earnings.</p>
              <Link className='btn' to="/login"> Login</Link>

            </div>

            <div className='trading-sec'>
              <div className="tradingview-widget-container">
                {/* <iframe src="https://www.tradingview-widget.com/embed-widget/ticker-tape/?locale=in#%7B%22symbols%22%3A%5B%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3AETHUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3ABTCUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3ABNBUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3ASOLUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3AAVAXUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3AXRPUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3AMATICUSDT%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Atrue%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A76%2C%22utm_source%22%3A%22in.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22in.tradingview.com%2Fwidget%2Fticker-tape%2F%22%7D" title="ticker tape TradingView widget" lang="en"></iframe> */}
                <iframe src="https://fxpricing.com/fx-widget/ticker-tape-widget.php?id=1,2,3,5,14,20&border=show&speed=50&click_target=blank&theme=light&tm-cr=FFFFFF&hr-cr=00000013&by-cr=28A745&sl-cr=DC3545&flags=rectangle&d_mode=regular&column=ask,bid&lang=en&font=Arial, sans-serif" title="ticker tape TradingView widget" lang="en"></iframe> 
              </div>
            </div>


            <div className='coin-sec'>
              <span className='animation-span coin-1' style={topObjectStyle}></span>
              <span className='animation-span coin-2' style={topObjectStyle} ></span>
              <span className='animation-span coin-3' style={topObjectStyle} ></span>
            </div>

          </div>
        </section>

        <section className='section-index section-banner-overly'>
          <div className='container-index '>
            <div className='banner-overly'>
              <img src={banner_overly} alt="sdf" />
            </div>
          </div>
        </section>

        <section className='section-index section-about'>
          <div className='container-index'>
            <div className='row'>
              <div className='col-12 heading-sec'>
                <h3 className='font-family-heading'>How It Works</h3>
                {/* <p> Automate trades with math and probability</p> */}
              </div>

              <div className='col-xl-3 col-lg-3 col-md-6 column-outer-sec'>
                <div className='sec'>
                  <div className='stap-sec'>
                    <span>1</span>
                  </div>
                  <h2> Create Your Account</h2>
                  <p> Sign up in just a few easy steps and join the GIC Capital community.</p>
                </div>
              </div>

              <div className='col-xl-3 col-lg-3 col-md-6 column-outer-sec'>
                <div className='sec'>
                  <div className='stap-sec'>
                    <span>2</span>
                  </div>
                  <h2> Fund Your Wallet</h2>
                  <p>Securely deposit your cryptocurrency into your GIC Capital account.</p>
                </div>
              </div>

              <div className='col-xl-3 col-lg-3 col-md-6 column-outer-sec'>
                <div className='sec'>
                  <div className='stap-sec'>
                    <span>3</span>
                  </div>
                  <h2> Choose Your Earning Strategy</h2>
                  <p>Select from a range of earning options tailored to suit your investment style and risk preference. </p>
                </div>
              </div>

              <div className='col-xl-3 col-lg-3 col-md-6 column-outer-sec'>
                <div className='sec'>
                  <div className='stap-sec'>
                    <span>4</span>
                  </div>
                  <h2> Watch Your Earnings Grow</h2>
                  <p>Track your portfolio’s performance and enjoy the fruits of smart crypto investing.</p>
                </div>
              </div>



              {/* <div className='col-12 mt-5'>
                <div className='section-media'>
                  <div className='row'>
                    <div className='col-12 mt-5 mb-5'>
                      <h3>Media loves us</h3>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                      <div className='media-card-sec'>

                        <img src={mediaSec} alt="media" width="100%" />
                      </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-sm-6'>
                      <div className='media-card-sec'>

                        <img src={mediaSec} alt="media" width="100%" />
                      </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-sm-6'>
                      <div className='media-card-sec'>

                        <img src={mediaSec} alt="media" width="100%" />
                      </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-sm-6'>
                      <div className='media-card-sec'>

                        <img src={mediaSec} alt="media" width="100%" />
                      </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-sm-6'>
                      <div className='media-card-sec'>

                        <img src={mediaSec} alt="media" width="100%" />
                      </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-sm-6'>
                      <div className='media-card-sec'>

                        <img src={mediaSec} alt="media" width="100%" />
                      </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-sm-6'>
                      <div className='media-card-sec'>

                        <img src={mediaSec} alt="media" width="100%" />
                      </div>
                    </div>

                    <div className='col-lg-3 col-md-4 col-sm-6'>
                      <div className='media-card-sec'>

                        <img src={mediaSec} alt="media" width="100%" />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

        </section>



        <section className='section-index section-wallet d-none'>
          <div className='container-index'>
            <div className='wallet-card'>
              <div className='row '>
                <div className='col-md-6'>
                  <h2>Three in One. Super Wallet</h2>
                  <p>The app provides design and digital marketing, applied arts can include industrial design</p>
                  <ul>
                    <li>1. STORE</li>
                    <li>2. EARN</li>
                    <li>3. TRADE</li>
                  </ul>

                  <div className='buttons'>
                    <Link to="#" className='btn'>
                      <img src={appleDownload} alt="apple-download" />
                    </Link>
                    <Link to="#" className='btn'>
                      <img src={googlePlay} alt="googlePlay-download" />
                    </Link>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='sec-img-wallet'>
                    <img src={wallet_sec} alt="wallet-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>




        <section className='section-index section-crypto'>
          <div className='container-index'>
            <div className='card-heading'>
              <h1>Enhanced Automation for Your Cryptocurrency <br /> Management.</h1>
              <p>Maximize earnings from every market trend with our comprehensive, fully automated trading system</p>
            </div>

            <div className='row justify-content-start'>
              <div className='col-md-8'>
                <div className='card'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='img-crypto-sec'>
                        <img src={crypto1} alt="img" />
                        <div className='images2 d-flex align-items-center justify-content-end'>
                          <img src={crypto2} alt="img" className='img2' />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='text-crypto-sec'>
                        <h5> Monitor your portfolio instantly and continuously.</h5>
                        <p> Experience the convenience of real-time updates, allowing you to stay informed of every change in your portfolio's performance.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className='row justify-content-center mt-4'>
              <div className='col-md-8'>
                <div className='card'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='img-crypto-sec'>
                        <img src={crypto4} alt="img" className='crypto-img-4' />
                        <Link to="/login" className='btn'> Login</Link>

                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='text-crypto-sec'>
                        <h5> Receive a Detailed Monthly Analysis of Your Portfolio's Performance.</h5>
                        <p> Dive deep into your investment outcomes with our comprehensive monthly reports, featuring in-depth analysis and performance metrics.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className='row justify-content-end mt-4'>
              <div className='col-md-8'>
                <div className='card'>
                  <div className='row'>
                    <div className='col-md-6'>


                      <div className='img-crypto-sec'>
                        <img src={crypto3} alt="img" className='crypto-img-3' />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='text-crypto-sec'>
                        <h5> Your data remains confidential; we never disclose it to third parties.</h5>
                        <p>Rest assured, your privacy is our top priority. We implement advanced security measures to protect your sensitive information at all times.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='section-index section-details'>
          <div className='container-index'>
            <div className='sec-heading'>
              <h4>Key Features and Benefits</h4>
            </div>
            <div className='row mt-5'>
              <div className='col-md-6'>
                <div className='sec-1'>
                  <div className='sec-img'>
                    <span> <Security /></span>
                  </div>
                  <div className='sec-text'>
                    <h4>Advanced Security Measures</h4>
                    <p>At GIC Capital, we prioritize the safety of your assets with cutting-edge security protocols.</p>
                  </div>

                  <svg x="0px" y="0px" className='svg-main'>
                    <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)" stroke-width="1" stroke-dasharray="1300" stroke-dashoffset="0" d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"></path>
                    <path className="dashed2" fill="none" stroke="#ffffff" stroke-width="2" stroke-dasharray="6" stroke-dashoffset="1300" d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "></path>
                  </svg>
                </div>


                <div className='sec-1 sec-1-reverse'>
                  <div className='sec-img'>
                    <span> <StackedBarChart /></span>
                  </div>
                  <div className='sec-text'>
                    <h4> Diverse Investment Options</h4>
                    <p>From staking to yield farming, discover a variety of ways to grow your crypto holdings.</p>
                  </div>
                </div>


                <div className='sec-1'>


                  <svg x="0px" y="0px" className='svg-main-2'>
                    <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)" stroke-width="1" stroke-dasharray="1300" stroke-dashoffset="0" d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"></path>
                    <path className="dashed2" fill="none" stroke="#ffffff" stroke-width="2" stroke-dasharray="6" stroke-dashoffset="1300" d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"></path>
                  </svg>


                  <div className='sec-img'>
                    <span> <Person2 /></span>
                  </div>
                  <div className='sec-text'>
                    <h4>  User-Friendly Interface:</h4>
                    <p>Our platform is designed for ease of use, making crypto earning straightforward and enjoyable.</p>
                  </div>

                </div>
              </div>

              <div className='col-md-6'>
                <div className='chart-sec-img'>
                  <img src={chart_sec} alt="chart-sec" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='section-index coins-section'>
          <div className='container-index '>
            <div className='heading-sec'>
              <h3>Build your crypto portfolio</h3>
            </div>

            <div className='row-outer-sec'>
              <div className='row'>
                <div className='col-12 left-sec'>
                  <Swiper
                    spaceBetween={30}
                    loop={true}
                    // centeredSlides={true}
                    autoplay={{
                      delay: 500,
                      disableOnInteraction: true,
                    }}

                    breakpoints={{

                      100: {
                        slidesPerView: 2,
                      },
                      480: {
                        slidesPerView: 2,
                      },
                      576: {
                        slidesPerView: 3,
                      },

                      768: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 6,
                      },
                    }}

                    pagination={{
                      clickable: true,
                    }}
                    // navigation={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={polkadot} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Polkadot</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={solana} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Salana</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={ripple} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Ripple</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={USD_tether} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Tether USD</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={cardano} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Cardano</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={ethereum} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Ethereum</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={bitcoin} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>bitcoin</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={uniswap} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Uniswap</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={usd_coin} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>USD Coin</p>
                        </div>
                      </div>
                    </SwiperSlide>


                  </Swiper>
                </div>
                <div className='col-12 right-sec'>
                  <Swiper
                    dir="rtl"
                    spaceBetween={30}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                      delay: 500,
                      disableOnInteraction: true,
                    }}

                    breakpoints={{

                      100: {
                        slidesPerView: 2,
                      },
                      480: {
                        slidesPerView: 2,
                      },
                      576: {
                        slidesPerView: 3,
                      },

                      768: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 6,
                      },
                    }}

                    pagination={{
                      clickable: true,
                    }}
                    // navigation={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Filecoin} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Filecoin</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Polygon} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Polygon</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Monero} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Monero</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Wrapped_Bitcoin} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Wra. Bitcoin</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Algorand} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Algorand</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={bitcoin_cash} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>bitcoin cash</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Litecoin} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Litecoin</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Chainlink} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Chainlink</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Lumen} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Lumen</p>
                        </div>
                      </div>
                    </SwiperSlide>


                  </Swiper>
                </div>

                <div className='col-12 left-sec'>
                  <Swiper
                    spaceBetween={30}
                    loop={true}
                    // centeredSlides={true}
                    autoplay={{
                      delay: 500,
                      disableOnInteraction: true,
                    }}

                    breakpoints={{

                      100: {
                        slidesPerView: 2,
                      },
                      480: {
                        slidesPerView: 2,
                      },
                      576: {
                        slidesPerView: 3,
                      },

                      768: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 6,
                      },
                    }}

                    pagination={{
                      clickable: true,
                    }}
                    // navigation={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={polkadot} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Polkadot</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={solana} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Salana</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={ripple} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Ripple</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={USD_tether} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Tether USD</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={cardano} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Cardano</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={ethereum} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Ethereum</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={bitcoin} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>bitcoin</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={uniswap} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Uniswap</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={usd_coin} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>USD Coin</p>
                        </div>
                      </div>
                    </SwiperSlide>


                  </Swiper>
                </div>
                <div className='col-12 right-sec'>
                  <Swiper
                    dir="rtl"
                    spaceBetween={30}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                      delay: 500,
                      disableOnInteraction: true,
                    }}

                    breakpoints={{

                      100: {
                        slidesPerView: 2,
                      },
                      480: {
                        slidesPerView: 2,
                      },
                      576: {
                        slidesPerView: 3,
                      },

                      768: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 6,
                      },
                    }}

                    pagination={{
                      clickable: true,
                    }}
                    // navigation={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Filecoin} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Filecoin</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Polygon} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Polygon</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Monero} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Monero</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Wrapped_Bitcoin} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Wra. Bitcoin</p>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Algorand} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Algorand</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={bitcoin_cash} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>bitcoin cash</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Litecoin} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Litecoin</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Chainlink} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Chainlink</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='coin-box'>
                        <img src={Lumen} alt="coin_imgs" />
                        <div className='coin-box-text'>
                          <p>Lumen</p>
                        </div>
                      </div>
                    </SwiperSlide>


                  </Swiper>
                </div>

              </div>
            </div>
          </div>
        </section>


        <section className='section-index section-partner'>
          <div className='container-index'>
            <div className='row'>
              <div className='col-12'>
                <h2>The first company ever to put trading data on a blockchain</h2>
              </div>
              <div className='col-12'>
                <div className='blockchain-buttons'>
                  <Link to="/" className='btn'>
                    <img src={blockchin_binnace} alt='blockchain' />
                  </Link>

                  <Link to="/" className='btn'>
                    <img src={blockchain_eth} alt='blockchain' />
                  </Link>

                  {/* <Link to="/" className='btn'>
                    <img src={blockchin_starakk} alt='blockchain' />
                  </Link> */}
                </div>

              </div>
            </div>

          </div>

          <div className='container-index mt-5'>
            <div className='heading-sec'>
              <h2>Exchanges we trade on</h2>
            </div>

            <div className='row exchange-sec'>
              <ul>
                <li>
                  <Link to="/" className='btn exchange-sec'>
                    <img src={exchange_binance} alt='exchange_imgs' />
                  </Link>
                </li>

                <li>
                  <Link to="/" className='btn exchange-sec'>
                    <img src={exchange_bybit} alt='exchange_imgs' />
                  </Link>
                </li>

                <li>
                  <Link to="/" className='btn exchange-sec'>
                    <img src={exchange_coinbase} alt='exchange_imgs' />
                  </Link>
                </li>
                <li>
                  <Link to="/" className='btn exchange-sec'>
                    <img src={exchange_kucoin} alt='exchange_imgs' />
                  </Link>
                </li>
              </ul>



            </div>

          </div>

        </section>




        <section className='section-index section-download'>
          <div className='slider-all-sec '>

            <div className="dowanload_slider">
              <div className="downlist">
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
              </div>
            </div>

            <div className='slider-sec'>
              <Swiper
                spaceBetween={30}
                loop={true}
                // centeredSlides={true}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: true,
                }}

                breakpoints={{

                  0: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 5,
                  },
                }}

                pagination={{
                  clickable: true,
                }}
                // navigation={true}
                modules={[Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="text_block">
                    <span>Download </span>
                    <span className="mark_star">•</span>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>

            <div className="dowanload_slider">
              <div className="downlist">
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Download </span>
                  <span className="mark_star">•</span>
                </div>
              </div>
            </div>
          </div>

          <div className='img-sec-slider'>
            <img src={download_phone} alt='download-phone-view' />
            <div className='buttons'>
              <Link to="#" className='btn'>
                <img src={appleDownload} alt="apple-download" />
              </Link>
              <Link to="#" className='btn'>
                <img src={googlePlay} alt="googlePlay-download" />
              </Link>
            </div>
          </div>
        </section>



      </div>

      {/* {open && (
        <Modal open={open} onClose={closeModal} center
          classNames={{
            modal: "homepage_modal",
          }}>
          <div className="banner_modal">
            <img src={light_banner1} alt="banner" className='banner_img' />
          </div>
        </Modal>
      )} */}
    </>)
}

export default Index;
