import React, { useEffect, useState } from "react";
import { api_url, titleDescription } from "../../config/Config";
import profession1 from '../../Assets/Image/profession1.png';
import premium from '../../Assets/Image/premium.png';
import premium_1 from '../../Assets/Image/premium_1.png';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import _fetch from "../../config/api";
import toasted from "../../config/toast";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { useTranslation } from 'react-i18next';
import Loader from "../../config/Loader";

const BuyInfra = (props: any) => {
    const { t, i18n } = useTranslation();


    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });
    const [open, setOpen] = useState(false);

    // const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [professoinalPackage, setProfessoinalPackage] = useState({ title: "", package_range: "", _id: "" });
    const [advancedPackage, setAdvancedPackage] = useState({ title: "", package_range: "", _id: "" });
    const [premiumPackage, setPremiumPackage] = useState({ title: "", package_range: "", _id: "" });
    const [valueChange, setValueChange] = useState('');
    const [method, setMethod] = useState(1);
    const [userName, setUserName] = useState('');
    const [avaliableFund, setAvaliableFund] = useState({ fundValue: "", reBuyValue: "" });
    const [loading, setLoading] = useState(true);
    const [btnDisable, setbtnDisable] = useState(false);


    useEffect(() => {
        getPackage();
        getAvaliableFund();
    }, []);

    const Navigate = useNavigate();

    const getPackage = async () => {
        let res = await _fetch(`${api_url}investment/get-package`, "GET", {})
        if (res?.status === 'success') {
            setProfessoinalPackage(res?.Package?.[2]);
            setAdvancedPackage(res?.Package?.[0]);
            setPremiumPackage(res?.Package?.[1]);
        }
    }

    const getAvaliableFund = async () => {
        setLoading(true);
        let res = await _fetch(`${api_url}profile/avaliablefund`, "GET", {})
        if (res?.status === "success") {
            setLoading(false);
            setAvaliableFund(res?.data?.AvaliableDeposit);
        }
    }

    const BuyNow = async (type: any) => {
        setLoading(true);
        setbtnDisable(true)
        let data = {
            "username": userName,
            "amount": valueChange,
            "investCode": type === 'advance' ? advancedPackage?._id : type === 'premium' ? premiumPackage?._id : type === 'professional' ? professoinalPackage?._id : " ",
            "paymentMethod": method
        }
        let res = await _fetch(`${api_url}investment/invest-now`, "POST", data)
        if (res?.status === 'success') {
            toasted.success(res?.message);
            setLoading(false);
            Navigate("/dashboard");
            setbtnDisable(false)
        }
        else {
            toasted.error(res?.message);
            setLoading(false);
            setbtnDisable(false)
        }
    }

    const [findedUserName, SetfindedUserName] = useState();
    const checkUser = async (user_id: any) => {
        let res = await _fetch(`${api_url}auth/checkUser?user=${user_id}`, "GET", {})
        if (res?.status === "success") {
            SetfindedUserName(res?.data?.name);
        }
    }

    return (
        <>
            <main>
                <div className="buy_section loader_main">
                    {loading &&
                        <div className="spinner_bg">

                            <div className="color_ring">  <ColorRing
                                visible={true}
                                height="80"
                                width="80"

                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                            /></div>

                        </div>
                    }

                    <div className="coustom_container">
                        <div className="buy_top_sec">
                            <h4>
                                {t("BUY INFRA")}
                            </h4>
                            {/* <p><span>Avaliable balance:</span>
                                <span>
                                    Buy Fund : ${Number(avaliableFund?.fundValue)?.toFixed(2)}
                                </span>
                                <span>
                                    Rebuy Fund : ${Number(avaliableFund?.reBuyValue)?.toFixed(2)}
                                </span>

                            </p> */}

                            <div className="inner_data">
                                {/* <p>Avaliable balance:</p> */}
                                <p className="">{t("Topup Fund Balance")} : ${Number(avaliableFund?.fundValue)?.toFixed(2)}</p>
                                {/* <p> Rebuy Fund : ${Number(avaliableFund?.reBuyValue)?.toFixed(2)}</p> */}
                            </div>
                            {/* <div className="top_btn">
                                <button className="button_style">Myself</button>
                                <button className="button_style">Downline</button>
                            </div> */}
                        </div>

                        <div className="row cards_sec">
                            {/* <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="card christmas_card mb-4">

                                    <div className="card-body">
                                        <img src={christmas_tree} className="christmas" alt="img" />
                                        <h3>Christmas Infra</h3>
                                        <div className="card_bottom_Sec">
                                            <div className="inc_section">
                                                <h6>INC</h6>
                                                <h6>+5%</h6>
                                            </div>
                                            <div className="inc_section">
                                                <h6>{t("MAX EARN")}</h6>
                                                <h6>700%</h6>
                                            </div>
                                            <div className="buy-section">
                                                <p className="select_amount">

                                                    <select className="form-select" aria-label="Default select example">
                                                        <option selected>Select Amount</option>
                                                        <option value="1">$10,000</option>
                                                        <option value="2">$15,000</option>
                                                        <option value="3">$20,000</option>
                                                    </select>
                                                </p>
                                                <button className="button_style mt-3 w-100" onClick={onOpenModal}>BUY NOW</button>
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div> */}



                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="card christmas_card mb-4">

                                    <div className="card-body">
                                        <img src={premium} className="christmas" alt="img" />
                                        <h3>{t(advancedPackage?.title)}</h3>
                                        <div className="card_bottom_Sec">
                                            {/* <div className="inc_section">
                                                <h6>INC</h6>
                                                <h6>+4%</h6>
                                            </div> */}
                                            <div className="inc_section">
                                                <h6>{t("MAX EARN")}</h6>
                                                <h6>350%</h6>
                                            </div>
                                            <div className="buy-section">
                                                <p className="select_amount">

                                                    {/* <select className="form-select" aria-label="Default select example">
                                                        <option selected>Select Amount</option>
                                                        <option value="1">$10,000</option>
                                                        <option value="2">$25,000</option>
                                                        <option value="3">$30,000</option>
                                                    </select> */}

                                                    <input type="number" placeholder={advancedPackage?.package_range ? `$${advancedPackage?.package_range.replace(",", "-$")}` : "-"} onChange={(e: any) => setValueChange(e.target.value)} />
                                                </p>

                                                <p className="select_amount">
                                                    <input type="text" placeholder={t("enter username")} onBlur={(e: any) => { setUserName(e.target.value); checkUser(e.target.value) }} />
                                                    <span className="text-success">{findedUserName}</span>
                                                </p>
                                                <p className="select_amount">
                                                    <select className="form-select" aria-label="Default select example" onChange={(e: any) => setMethod(e.target.value)}>
                                                        {/* <option value="0" selected>Select Payment Method</option> */}
                                                        <option value="1">{t("Buy From Topup Fund")}</option>
                                                        {/* <option value="2">Buy From Wallet Fund</option> */}
                                                    </select>
                                                </p>

                                                <button className="button_style w-100 mt-3" onClick={() => BuyNow("advance")} disabled={btnDisable}>


                                                    {`${t("BUY NOW")}`}
                                                </button>
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="card christmas_card mb-4">

                                    <div className="card-body">
                                        <img src={premium_1} className="christmas" alt="img" />
                                        <h3>{t(premiumPackage?.title)}</h3>
                                        <div className="card_bottom_Sec">
                                            {/* <div className="inc_section">
                                                {/* <h6>INC</h6>
                                                <h6>+9%</h6> */}
                                            {/* </div>  */}
                                            <div className="inc_section">
                                                <h6>{t("MAX EARN")}</h6>
                                                <h6>400%</h6>
                                            </div>
                                            <div className="buy-section">
                                                <p className="select_amount">

                                                    {/* <select className="form-select" aria-label="Default select example">
                                                        <option selected>Select Amount</option>
                                                        <option value="1">$10,000</option>
                                                        <option value="2">$15,000</option>
                                                        <option value="3">$25,000</option>
                                                    </select> */}

                                                    <input type="number" placeholder={premiumPackage?.package_range ? `$${premiumPackage?.package_range.replace(",", "-$")}` : "-"} onChange={(e: any) => setValueChange(e.target.value)} />
                                                </p>

                                                <p className="select_amount">
                                                    <input type="text" placeholder={t("enter username")} onBlur={(e: any) => { setUserName(e.target.value); checkUser(e.target.value) }} />
                                                    <span className="text-success">{findedUserName}</span>
                                                </p>
                                                <p className="select_amount">
                                                    <select className="form-select" aria-label="Default select example" onChange={(e: any) => setMethod(e.target.value)}>
                                                        {/* <option selected>Select Payment Method</option> */}
                                                        <option value="1">{t("Buy From Topup Fund")}</option>
                                                        {/* <option value="2">Buy From Wallet Fund</option> */}
                                                    </select>
                                                </p>

                                                <button className="button_style w-100 mt-3" onClick={() => BuyNow("premium")} disabled={btnDisable}>


                                                    {`${t("BUY NOW")}`}

                                                </button>
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="card christmas_card mb-4">

                                    <div className="card-body">
                                        <img src={profession1} className="christmas" alt="img" />
                                        <h3>{t(professoinalPackage?.title)}</h3>
                                        <div className="card_bottom_Sec">
                                            {/* <div className="inc_section">
                                                <h6>INC</h6>
                                                <h6>+7%</h6>
                                            </div> */}
                                            <div className="inc_section">
                                                <h6>{t("MAX EARN")}</h6>
                                                <h6>500%</h6>
                                            </div>
                                            <div className="buy-section">
                                                <p className="select_amount">

                                                    {/* <select className="form-select" aria-label="Default select example">
                                                        <option selected>Select Amount</option>
                                                        <option value="1">$10,000</option>
                                                        <option value="2">$20,000</option>
                                                        <option value="3">$30,000</option>
                                                    </select> */}

                                                    <input type="number" placeholder={professoinalPackage?.package_range ? `$${professoinalPackage?.package_range.replace(',', '-$')}` : "-"} onChange={(e: any) => { setValueChange(e.target.value) }} />
                                                </p>

                                                <p className="select_amount">
                                                    <input type="text" placeholder={t("enter username")} onChange={(e: any) => setUserName(e.target.value)} />
                                                    <span className="text-success">{findedUserName}</span>
                                                </p>
                                                <p className="select_amount">
                                                    <select className="form-select" aria-label="Default select example" onBlur={(e: any) => { setMethod(e.target.value); checkUser(e.target.value) }}>
                                                        {/* <option selected>Select Payment Method</option> */}
                                                        <option value="1">{t("Buy From Topup Fund")}</option>
                                                        {/* <option value="2">Buy From Wallet Fund</option> */}
                                                    </select>
                                                </p>

                                                <button className="button_style mt-3 w-100 " onClick={() => BuyNow("professional")} disabled={btnDisable}>


                                                    {`${t("BUY NOW")}`}

                                                </button>
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
            <Modal open={open} onClose={onCloseModal} center classNames={{
                modal: "buy_modal",

            }}>
                <p className="modal_data mb-3">
                    <h6>Price</h6>
                    <h6>$6,000</h6>
                </p>
                <p className="modal_data mb-3">
                    <h6>Available</h6>
                    <h6>$68,000</h6>
                </p>
                <p className="modal_data mb-3">
                    <h6>Rebuy</h6>
                    <h6>$22,000</h6>
                </p>
                <p className="need">Need 3,888.50 in wallet to make a purchase.</p>
                <div className="check_input">
                    <input type="checkbox" placeholder="" /><label>Use Rebuy Amount</label>

                </div>
                <p className="mt-3 mb-4">(20% of Infra Price can be used from Rebuy)</p>
                <a href="/deposit" className="button_style load_btn d-flex justify-content-center">Load eUSD</a>


            </Modal>
        </>

    );
};

export default BuyInfra;
