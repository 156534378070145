import React, { useEffect, useState } from 'react'
import { api_url, dark_logo, logo, website } from '../config/Config'
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AccountBalanceWallet, AutoStories, CloseSharp, Dashboard, Description, Groups, Help, HowToReg, Logout, MonetizationOn, Payment, PersonOutline, ShoppingBasket } from '@mui/icons-material';
import _fetch from '../config/api';
import toasted from '../config/toast';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {

  const { t, i18n } = useTranslation();

  let location = useLocation();
  const [showSubmenu, setShowSubmenu] = useState(false);

  const toggleSubmenu = () => {
    setShowSubmenu(!showSubmenu);
  };

  const navigate = useNavigate();

  const logOut = async () => {

    var data = await _fetch(`${api_url}auth/logout`, 'GET', {});

    if (data?.status === 'success') {

      toasted.success(data?.message);
      localStorage.clear();
      window.location.reload();
      navigate('/login');
    } else {
      toasted.error(data?.message);
    }
  }
  const [sponsorId, setSponsorId] = useState('');

  useEffect(() => {

    // if (auth === "true") {
    (async () => {
      const data1 = await _fetch(`${api_url}user/userInfo/`, "GET", {});
      if (data1.status === 'success') {
        if (data1?.data?.data) {
          setSponsorId(data1?.data?.data?.username);
        }
      } else {
        localStorage.clear();
        navigate("/login");
      }
    })();
    // }
  }, [location.pathname]);




  const ToggleButtonRemover = () => {
    const body = document.body;
    body.classList.remove("show-index-bar");
  }
  const [theme, setTheme] = useState('light');

  const modechange = () => {
    if (theme === 'light') {
      setTheme('dark');
    }
    else {
      setTheme('light');
    }
    // setTheme(theme == 'light' ? 'dark' : 'light');

    localStorage.setItem('mode', theme);
  }

  // console.log("theme", theme);


  let modeTheme = localStorage.getItem("mode");

  useEffect(() => {
    const body = document.body;
    body.setAttribute("data-theme", `${modeTheme}`);

  }, [theme]);

  return (
    <>

      <div className="app-sidebar">
        <div className='sidbar-overly' onClick={ToggleButtonRemover}></div>


        <div className="app-sidebar3">
          <div className="sidebar">
            <div className="app-sidebar__logo">
              <Link className="header-brand" to="/dashboard">
                <div className="mode"
                // onClick={modechange}
                >
                  <img src={dark_logo} className="header-brand-img dark-logo" alt="logo" />

                  <img src={logo} className="header-brand-img light-logo" alt="logo" />

                </div>

              </Link>

              <button type='button' className='close-btns' onClick={ToggleButtonRemover}>
                <CloseSharp />
              </button>
            </div>
            <ul className="sidebar_menu">

              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className='only_button'>
                    <Link to="/dashboard" onClick={ToggleButtonRemover} className={location.pathname === '/dashboard' ? "drop_link active_Link" : ' drop_ink'}><Dashboard />{t("Dashboard")}</Link>
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header className='only_button'>
                    <Link to="/profile" onClick={ToggleButtonRemover} className={location.pathname === '/profile' ? "drop_link active_Link" : ' drop_ink'}><PersonOutline />{t("Profile")}</Link>
                  </Accordion.Header>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className='only_button'>
                    <Link onClick={ToggleButtonRemover} to={`${website}register/${sponsorId}`} target="_blank" className={location.pathname === '/register' ? "drop_link active_Link" : ' drop_ink'} ><HowToReg />{t("New Member")}</Link>
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header className='only_button'>
                    <Link to="/main-overview" onClick={ToggleButtonRemover} className={location.pathname === '/main-overview' ? "drop_link active_Link" : ' drop_ink'}><AccountBalanceWallet />{t("Overview")}</Link>
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header className='only_button'>
                    <Link to="/deposit" onClick={ToggleButtonRemover} className={location.pathname === '/deposit' ? "drop_link active_Link" : ' drop_ink'}><MonetizationOn />{t('Deposit')}</Link>
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header><span><ShoppingBasket />{t("Infrastructure")}</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><Link onClick={ToggleButtonRemover} to='/buy-infra'> {t("Buy Infra")}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to="/trading-profit">{t("Buy History")}</Link></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>


                <Accordion.Item eventKey="4">
                  <Accordion.Header className='only_button'>
                    <Link to="/team" onClick={ToggleButtonRemover} className={location.pathname === '/team' ? "drop_link active_Link" : ' drop_ink'}><Groups />{t("Team")}</Link>
                  </Accordion.Header>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="5">
                  <Accordion.Header className='only_button'>
                    <Link to="/overview" className={location.pathname === '/overview' ? "drop_link active_Link" : ' drop_ink'}><ViewCompact />{t("Overview")}</Link>
                  </Accordion.Header>
                </Accordion.Item> */}

                <Accordion.Item eventKey="6">
                  <Accordion.Header><span><AccountBalanceWallet />{t("Income")}</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><Link onClick={ToggleButtonRemover} to='/report/direct_income'>{t("Direct Profit")}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/report/level_income'>{t("Layered Investment Returns")}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/report/roi_income'>{t("Profit on Investment")}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/report/royalty_income'>{t("Royalty Profit")}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/report/global_lifetime_pool_income'>{t("Eternal Global Pool Profit")}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/report/booster_income'>{t("Booster Profit")}</Link></li>


                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                  <Accordion.Header className='only_button'>
                    <Link onClick={ToggleButtonRemover} to="/ledger" className={location.pathname === '/ledger' ? "drop_link active_Link" : ' drop_ink'}><Description />{t("Ledger")}</Link>
                  </Accordion.Header>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="8">
                  <Accordion.Header><span><CardGiftcard />Promotions</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><a>IGNITE</a></li>
                      <li><a>Golden Diwali</a></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item> */}

                {/* <Accordion.Item eventKey="9">
                  <Accordion.Header className='only_button'>
                    <Link onClick={ToggleButtonRemover} to="/" className={location.pathname === '/Cryptic' ? "drop_link active_Link" : ' drop_ink'}><Key />Cryptic ID</Link>
                  </Accordion.Header>
                </Accordion.Item> */}

                <Accordion.Item eventKey="10">
                  <Accordion.Header><span><Payment />{t("Payout")}</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li><Link onClick={ToggleButtonRemover} to='/payout-request'>{t("Request")}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/pay-history'>{t("History")}</Link></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                  <Accordion.Header className='only_button'>
                    <Link onClick={ToggleButtonRemover} to="/presentations" className={location.pathname === '/presentations' ? "drop_link active_Link" : ' drop_ink'}><AutoStories />{t("Presentations")}</Link>
                  </Accordion.Header>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="12">
                  <Accordion.Header className='only_button'>
                    <Link to="/support" className={location.pathname === '/support' ? "drop_link active_Link" : ' drop_ink'}><Help />Support</Link>
                  </Accordion.Header>
                </Accordion.Item> */}
                <Accordion.Item eventKey="12">
                  <Accordion.Header><span><Help />{t("Support")}</span></Accordion.Header>
                  <Accordion.Body>
                    <ul>

                      <li><Link onClick={ToggleButtonRemover} to='/create-ticket'>{t("Create Ticket")}</Link></li>
                      <li><Link onClick={ToggleButtonRemover} to='/ticket-history'>{t("Ticket History")}</Link></li>

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="14">
                  <Accordion.Header className='only_button'>
                    <Link to="/" className={location.pathname === '/logout' ? "drop_link active_Link" : ' drop_ink'} onClick={logOut}><Logout />{t("Log out")}</Link>
                  </Accordion.Header>
                </Accordion.Item>

              </Accordion>


            </ul>
          </div>


        </div>
      </div>

    </>
  )
}

export default Sidebar