import { title } from 'process';
import React from 'react';
import shortLogo from '../Assets/Icon/shortLogo.png';
import { Link } from 'react-router-dom';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Col, Row } from 'react-bootstrap';


const ContactUs = () => {

    return (
        <>
            <div className='contact_us'>
                <div className='contact__banner'>
                    <div className='container-banner'>
                        <h1>Contact Us</h1>
                        <div className='banner___links'>
                            <Link to="/">Home <KeyboardArrowRight /></Link>
                            <p>Contact Us</p>
                        </div>
                    </div>
                </div>


                <div className='contact__about'>
                    <div className='container-banner'>
                        <Row>
                            <Col sm={6}>
                                <div className='card__img'>
                                    <img src="https://preview.codeless.co/converta/default/wp-content/uploads/2023/03/contact-exp-min.png" width="100%" />
                                </div>
                            </Col>

                            <Col sm={6}>
                                <div className='card__txt'>
                                    <h3>Growth strategies to be effective & competitive</h3>
                                    <p>Everything we do and dream up has a solid design impact. We create human-centered enterprise software that has the polished, snappy feel of the best consumer apps.</p>

                                    <Link to="/login" className='btn'> Get Start </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>


                <div className='form__sec'>
                    <div className='form__sec__sds'>

                        <h3>Have some questions?</h3>

                        <Row>
                            <Col sm={6}>
                                <div className='card__map'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2513.5049851132626!2d-4.147293623599213!3d50.95136707169219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486c3f430d700861%3A0x89ee9e6ac25869bd!2s37%20South%20St%2C%20Great%20Torrington%2C%20Torrington%20EX38%208AB%2C%20UK!5e0!3m2!1sen!2sin!4v1707468945784!5m2!1sen!2sin" loading="lazy"></iframe>
                                </div>
                            </Col>

                            <Col sm={6}>
                                <div className='card form__card_a'>
                                    
                                    <form>

                                        <div className="mb-3 mt-3">
                                            <label htmlFor="name" className="form-label">Supporting Email:</label>
                                            <input type="email" className="form-control" id="supportEmail" placeholder="Supporting Email" name="supportEmail" value="support@giccapital.uk" disabled />
                                        </div>

                                        <div className="mb-3 mt-3">
                                            {/* <label htmlFor="name" className="form-label">Name:</label> */}
                                            <input type="text" className="form-control" id="name" placeholder="Enter Your Name" name="name" />
                                        </div>

                                        <div className="mb-3 mt-3">
                                            {/* <label htmlFor="email" className="form-label">Email:</label> */}
                                            <input type="email" className="form-control" id="email" placeholder="Enter Your email" name="email" />
                                        </div>

                                        <div className=''>
                                            {/* <label htmlFor="comment">Comments:</label> */}
                                            <textarea className="form-control" rows={5} id="comment" placeholder='Enter Your Message' name="text"></textarea>
                                        </div>

                                        <div className='btn__scsss'>
                                            <button type="submit" className="btn subMIt__btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </Col>
                        </Row>

                    </div>



                </div>
            </div>

        </>
    );
}

export default ContactUs;
