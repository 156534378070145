import React, { useEffect, useState } from 'react';
import { CalendarMonth, Diversity1, Diversity2, Person } from '@mui/icons-material';
import { Table } from 'react-bootstrap';
import _fetch from '../../config/api';
import { api_url } from '../../config/Config';
import { useTranslation } from 'react-i18next';

const MainOverview = (props: any) => {
    const { t, i18n } = useTranslation();

    const [allUserData, setAllUserData] = useState({totalTeam:0, boosterIncome: 0, totalIncome: "", avaliableIncome: "", TotalDeposit: "", AvaliableDeposit: { fundValue: "", reBuyValue: "" }, directIncome: "", levelIncome: "", poolIncome: "", roiIncome: "", royaltyIncome: "",profitDetails:{todayProfit:0,yesterdayProfit:0,lastMonthProfit:0,currentMonthProfit:0,last7DaysProfit:0},  userInfo: { user_info: { name: "", income_limit: "", package: { current_package: "", previous_package: "", total_package: "" } } } });

    const getUserData = async () => {
      // setLoading(true);
      let res = await _fetch(`${api_url}profile/dashboard`, "GET", {})
  
      if (res?.status === "success") {
  
        setAllUserData(res?.data);
        console.log('res?.data',res?.data);
        
  
      }
    }


    const [info, setInfo] = useState({ business: {direct_business:0,team_business:0},directs:{total:0} });

  const getUserInfo = async () => {
    let res = await _fetch(`${api_url}user/userInfo/`, "GET", {})
    if (res?.status === 'success') {
      setInfo(res?.data?.data);
    }
  }
    useEffect(() => {
        document.title = `${props.pageTitle}`;
        getUserData();
        getUserInfo();
    },[]);

    return (
        <>
            <main>
                <div className='main-overview'>

                    <div className='main-card-overview card'>
                        <div className='card-header'>
                            <h4>{t("Overview")}</h4>
                        </div>

                        <div className='card-body'>
                            <div className='row'>

                                <div className='col-md-4'>
                                    <ul className='left-view-1'>
                                        <li>
                                            <div className='view-1-left'>
                                                <span> <Diversity2 /></span>
                                            </div>
                                            <div className='view-1-right'>
                                                <p>{t("Team")}</p>
                                                <p>{allUserData?.totalTeam}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='view-1-left'>
                                                <span> <Diversity1 /></span>
                                            </div>
                                            <div className='view-1-right'>
                                                <p>{t("Frontlines")}</p>
                                                <p>{info?.directs?.total}</p>
                                            </div>
                                        </li>
                                        {/* <li>
                                            <div className='view-1-left'>
                                                <span> <CalendarMonth /></span>
                                            </div>
                                            <div className='view-1-right'>
                                                <p>Today’s Business</p>
                                                <p>2</p>
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>

                                <div className='col-md-8'>
                                    <div className='right-view-vb'>

                                        <div className='left-vb-sec'>
                                            <div className='vb-sec-outer active'>
                                                <p>{t("Total Business")}</p>
                                                <p>${info?.business?.team_business}</p>
                                            </div>

                                            <div className='vb-sec-outer'>
                                                <p>{t("Yesterday")}</p>
                                                <p>${allUserData?.profitDetails?.yesterdayProfit}</p>
                                            </div>

                                            <div className='vb-sec-outer'>
                                                <p>{t("Last 7 Days")}</p>
                                                <p>${allUserData?.profitDetails?.last7DaysProfit}</p>
                                            </div>

                                            <div className='vb-sec-outer'>
                                                <p>{t("Current Month")} </p>
                                                <p>${allUserData?.profitDetails?.currentMonthProfit}</p>
                                            </div>

                                            <div className='vb-sec-outer'>
                                                <p>{t("Last Month")} </p>
                                                <p>${allUserData?.profitDetails?.lastMonthProfit}</p>
                                            </div>
                                        </div>

                                        <div className='left-vb-sec d-none'>
                                            <div className='vb-sec-outer active'>
                                                <p>Total Business</p>
                                                <p>${info?.business?.team_business}</p>
                                            </div>

                                            <div className='vb-sec-outer'>
                                                <p>Yesterday</p>
                                                <p>${allUserData?.profitDetails?.yesterdayProfit}</p>
                                            </div>

                                            <div className='vb-sec-outer'>
                                                <p>Last 7 Days</p>
                                                <p>${allUserData?.profitDetails?.last7DaysProfit}</p>
                                            </div>

                                            <div className='vb-sec-outer'>
                                                <p>Current Month </p>
                                                <p>${allUserData?.profitDetails?.currentMonthProfit}</p>
                                            </div>

                                            <div className='vb-sec-outer'>
                                                <p>Last Month </p>
                                                <p>${allUserData?.profitDetails?.lastMonthProfit}</p>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div className='col-md-6 d-none'>
                                    <div className='table-sec-bbn'>
                                        <div className='table-sec-bbn-header'>
                                            <h4>TOP COUNTRIES</h4>
                                        </div>

                                        <div className='table-sec-bbn-body'>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>S No</th>
                                                        <th>Country	</th>
                                                        <th>Business</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> 1</td>
                                                        <td> <span className='img-flags'></span>  England</td>
                                                        <td> $0</td>
                                                    </tr>
                                                    <tr>
                                                        <td> 2</td>
                                                        <td> <span className='img-flags'></span>  India</td>
                                                        <td> $0</td>
                                                    </tr>
                                                    <tr>
                                                        <td> 3</td>
                                                        <td> <span className='img-flags'></span>  -</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td> 4</td>
                                                        <td> <span className='img-flags'></span>  -</td>
                                                        <td>-</td>
                                                    </tr>
                                                    <tr>
                                                        <td> 5</td>
                                                        <td> <span className='img-flags'></span>  -</td>
                                                        <td> -</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6 d-none'>
                                    <div className='table-sec-bbn'>
                                        <div className='table-sec-bbn-header'>
                                            <h4>TOP RANK HOLDERS</h4>
                                        </div>

                                        <div className='table-sec-bbn-body'>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Country	</th>
                                                        <th>Level</th>
                                                        <th>Rank</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> Tiana Bell	</td>
                                                        <td> <span className='img-flags'></span>  England</td>
                                                        <td> 1</td>
                                                        <td>
                                                            <div className='rank-td-bbn'>
                                                                <span className='icon-bbn'> <Person /> </span> Executive
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td> 1	</td>
                                                        <td> <span className='img-flags'></span>  -</td>
                                                        <td> -</td>
                                                        <td> -</td>
                                                    </tr>
                                                    <tr>
                                                        <td> 1	</td>
                                                        <td> <span className='img-flags'></span>  -</td>
                                                        <td> -</td>
                                                        <td> -</td>
                                                    </tr>
                                                    <tr>
                                                        <td> 1	</td>
                                                        <td> <span className='img-flags'></span>  -</td>
                                                        <td> -</td>
                                                        <td> -</td>
                                                    </tr>
                                                    <tr>
                                                        <td> 1	</td>
                                                        <td> <span className='img-flags'></span>  -</td>
                                                        <td> -</td>
                                                        <td> -</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default MainOverview;
