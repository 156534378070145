import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal';
import { useLocation, useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import french_icon from '../Assets/Icon/french.webp';
import eng_icon from '../Assets/Icon/english.webp';
import arabic_icon from '../Assets/Icon/arabic.webp';
import chinese_icon from '../Assets/Icon/chinese.webp';
import german_icon from '../Assets/Icon/german.webp';
import hindi_icon from '../Assets/Icon/hindi.webp';
import 'react-responsive-modal/styles.css';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { api_url, website } from '../config/Config';
import _fetch from '../config/api';
import toasted from '../config/toast';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useLanguage } from "../LanguageContext";
import Select from 'react-select';


const Header = () => {

  const { selectedLanguage, changeLanguage } = useLanguage();
  const [selectLangSelect, setselectLangSelect] = useState({
    value: 'en',
    text: <span className="new__a"> English</span>,
    icon: <img src={eng_icon} className="option-img-a" width="20px" />

  });

  const changeLanguageInContext = (language) => {
    setselectLangSelect(language)
    changeLanguage(language.value);
    localStorage.setItem('language', language?.value);
  };

  const [open, setOpen] = useState(false);
  const [sponsorId, setSponsorId] = useState('');

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  let location = useLocation();
  const navigate = useNavigate();
  const [theme, setTheme] = useState('light');

  const modechange = () => {
    if (theme === 'light') {
      setTheme('dark');
    }
    else {
      setTheme('light');
    }
    // setTheme(theme == 'light' ? 'dark' : 'light');

    localStorage.setItem('mode', theme);
  }

  // console.log("theme", theme);


  let modeTheme = localStorage.getItem("mode");


  useEffect(() => {
    const body = document.body;
    body.setAttribute("data-theme", `${modeTheme}`);
  }, [theme]);
  useEffect(() => {

    // if (auth === "true") {
    (async () => {
      const data1 = await _fetch(`${api_url}user/userInfo/`, "GET", {});
      if (data1.status === 'success') {
        if (data1?.data?.data) {
          setSponsorId(data1?.data?.data?.username);
        }
      } else {
        localStorage.clear();
        navigate("/login");
      }
    })();
    // }
    let storeLanguage = localStorage.getItem("language");

    if (storeLanguage) {
      data?.map((val) => {
        if (storeLanguage == val.value) {
          setselectLangSelect(val);
          changeLanguage(val.value);
        }
      })
      // setselectLangSelect(storeLanguage)
      // console.log('storeLanguage',storeLanguage?.value);
    }
  }, [location.pathname]);

  const LogOut = async () => {
    var data = await _fetch(`${api_url}auth/logout`, 'GET', {});
    if (data?.status === 'success') {

      toasted.success(data?.message);
      localStorage.clear();
      window.location.reload();
      navigate('/login');
    } else {
      toasted.error(data?.message);
    }
  }

  const copyToClipboard = (address) => {

    var textField = document.createElement('textarea');
    textField.innerText = address;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toasted.success('Address copied successfull!');
  }


  const ToggleButton = () => {
    const absx = document.body;
    absx.classList.toggle("show-index-bar");
  }



  const data = [
    {
      value: 'en',
      text: <span className="new__a"> English</span>,
      icon: <img src={eng_icon} className="option-img-a" />

    },
    {
      value: 'fr',
      text: <span className="new__a"> French</span>,
      icon: <img src={french_icon} className="option-img-a" />
    },
    {
      value: 'arabic',
      text: <span className="new__a"> Arabic</span>,
      icon: <img src={arabic_icon} className="option-img-a" />
    },
    {
      value: 'chinese',
      text: <span className="new__a"> Chinese</span>,
      icon: <img src={chinese_icon} className="option-img-a" />
    },
    {
      value: 'german',
      text: <span className="new__a"> German</span>,
      icon: <img src={german_icon} className="option-img-a" />
    },
    {
      value: 'hindi',
      text: <span className="new__a"> Hindi</span>,
      icon: <img src={hindi_icon} className="option-img-a" />
    },
    // {
    //   value: 'indonesian',
    //   text: <span className="new__a"> indonesian</span>,
    //   icon: <img src={indonesian_icon} className="option-img-a" />
    // },
    // {
    //   value: 'italian',
    //   text: <span className="new__a"> italian</span>,
    //   icon: <img src={italian_icon} className="option-img-a" />
    // },
    // {
    //   value: 'japanese',
    //   text: <span className="new__a"> japanese</span>,
    //   icon: <img src={japanese_icon} className="option-img-a" />
    // },
    // {
    //   value: 'korean',
    //   text: <span className="new__a"> korean</span>,
    //   icon: <img src={korean_icon} className="option-img-a" />
    // },
    // {
    //   value: 'polish',
    //   text: <span className="new__a"> polish</span>,
    //   icon: <img src={polish_icon} className="option-img-a" />
    // },
    // {
    //   value: 'portuguese',
    //   text: <span className="new__a"> portuguese</span>,
    //   icon: <img src={portuguese_icon} className="option-img-a" />
    // },
    // {
    //   value: 'russian',
    //   text: <span className="new__a"> russian</span>,
    //   icon: <img src={russian_icon} className="option-img-a" />
    // },
    // {
    //   value: 'spanish',
    //   text: <span className="new__a"> spanish</span>,
    //   icon: <img src={spanish_icon} className="option-img-a" />
    // },
    // {
    //   value: 'turkish',
    //   text: <span className="new__a"> turkish</span>,
    //   icon: <img src={turkish_icon} className="option-img-a" />
    // },
    // {
    //   value: 'vietnamese',
    //   text: <span className="new__a"> vietnamese</span>,
    //   icon: <img src={vietnamese_icon} className="option-img-a" />
    // },

  ];

  return (
    <>
      <div className="app-header">
        <div className='for_toggle_scan'>
          <div className="app-sidebar__toggle" data-toggle="sidebar">
            <a className="open-toggle" href="#">
              <svg className="header-icon mt-1" onClick={ToggleButton} xmlns="http://www.w3.org/2000/svg" height="24"
                viewBox="0 0 24 24" width="24">
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z"></path>
              </svg>
            </a>

          </div>

          <div className="header-left">
            <div className="link-copy ">
              <input type='text' value={`${website}register/${sponsorId}`} disabled></input>
              <div className="icon-copy">
                <FileCopyIcon onClick={() => { copyToClipboard(`${website}register/${sponsorId}`) }} />
              </div>
            </div>
            <div className="icons_im">
              <QRCode className="scan_modal__indf" value={`${website}register/${sponsorId}`} onClick={onOpenModal} />

              {/* <img src={`${website}register/${sponsorId}`} className="scan_code" alt="scan" onClick={onOpenModal} /> */}


              <Modal open={open} onClose={onCloseModal} center
                classNames={{
                  modal: "qr_modal",
                }}>
                <QRCode className="scan_modal" value={`${website}register/${sponsorId}`} />
              </Modal>
            </div>
          </div>
        </div>
        <div className="right-header-sec">



          <Select
            isSearchable={false}

            placeholder=""
            value={selectLangSelect}
            className="select-option-s"
            options={data}
            onChange={changeLanguageInContext}
            // onChange={(e) => changeLanguageInContext(e.target.value)}
            getOptionLabel={(e) => (
              <div style={{ display: 'flex', alignItems: 'center', background: 'transparent' }} className="mian-select-2p2p" >
                {e.icon}
                <span style={{ marginLeft: '5px' }}>{e.text}</span>
              </div>
            )}
          />

          <button className='button_style' onClick={() => LogOut()}>Log Out</button>
          <div className="mode" onClick={modechange}>
            {
              modeTheme === 'dark' ? <DarkModeIcon className='dark' /> : <WbSunnyIcon className="light" />
            }

          </div>
        </div>
      </div>




    </>
  )
}

export default Header