import React, { useEffect } from 'react'
import { dark_logo, titleDescription } from '../../config/Config';
import { logo } from '../../config/Config';
import england from '../../Assets/Image/england.webp';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';



const Presentations = (props: any) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });

    return (
        <main>
            <div className="presentation_main">
                <div className="coustom_container">
                    <div className="present_top_sec">
                        <h4>
                            {t("PRESENTATIONS")}
                        </h4>

                    </div>
                    <div className="present_inner">
                        <div className="row present_row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="card present_card">
                                    <div className="card-body">
                                        <div className="image_logo">
                                            <div className="mode">
                                                <img src={dark_logo} className="header-brand-img dark-logo" alt="logo" />

                                                <img src={logo} className="header-brand-img light-logo" alt="logo" />

                                            </div>
                                            <a href="../../../gic_capital.pdf" className='down_icon' download> <DownloadIcon/></a>
                                            
                                        </div>
                                        <div className="country_sec">
                                            <h6>ENGLAND</h6>
                                            <img src={england} className='logo_sec' alt="logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    )
}

export default Presentations