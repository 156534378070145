import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { api_url, titleDescription } from "../../config/Config";
import _fetch from "../../config/api";
import QRCode from "qrcode.react";
import toasted from "../../config/toast";
import dateformat from "dateformat";
import { ColorRing } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';


const Deposit = (props: any) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });

  const [wallets, setWallets] = useState({ bep20: "", trc20: "" });
  const [BEP20, setBEP20] = useState('');
  const [barcodeValue, setBarCodeValue] = useState(wallets?.bep20);
  const [walletHeding, setWalletHeding] = useState("USDT - BEP20 Deposit");
  const [barCodeHeding, setBarCodeheading] = useState('USDT - BEP20');
  const [trxType, setTrxType] = useState('bep20');
  const [countHit, setCountHit] = useState(0);
  const [fundValues, setFundValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [keyCount, setKeycount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    getWallets();
  }, []);

  const getWallets = async () => {
    let data = await _fetch(`${api_url}auth/getWallets`, "GET", {})

    if (data?.status === "success") {
      setWallets(data);
    }
  }


  useEffect(() => {
    getfundData();
  }, [page, limit]);

  const getfundData = async () => {
    setLoading(true);
    let data = await _fetch(`${api_url}fund/getfund?limit=${limit}&page=${page}`, "GET", {})

    if (data?.status === "success") {
      setLoading(false);
      setFundValues(data?.data);
      setTotalRecords(data?.total);

    }
    if (page > 1) {
      setKeycount(0);
      var count = page - 1;
      setKeycount(count * limit);
    }
  }

  useEffect(() => {
    if (countHit < 50) {
      const timer = setInterval(() => {
        checkTransaction();
        var calHit = parseFloat(`${countHit + 1}`);
        setCountHit(calHit);
      }, 10000);
      return () => clearInterval(timer);
    }
  }, [countHit]);

  const checkTransaction = async () => {
    // setLoading(true);
    const encodedBlockchain = encodeURIComponent(trxType);

    let data = await _fetch(`${api_url}fund/checktxn?blockchain=${encodedBlockchain}`, "GET", {})

    if (data?.status === "success") {
      // setLoading(false);
      toasted.success(data?.message);
      getfundData();
    }
  }

  function bep20wallet() {
    setBEP20(wallets?.bep20);
    setBarCodeValue(wallets?.bep20);
    setWalletHeding("USDT - BEP20 Deposit");
    setBarCodeheading("USDT - BEP20");
    setTrxType('bep20');
  }

  function trc20wallet() {
    setBEP20(wallets?.trc20);
    setBarCodeValue(wallets?.trc20);
    setWalletHeding("USDT - TRC20 Deposit");
    setBarCodeheading("USDT - TRC20");
    setTrxType('trc20');

  }

  const copyToClipboard = (address: any) => {

    var textField = document.createElement('textarea');
    textField.innerText = address;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toasted.success('Address copied successfull!');
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };

  return (
    <main>
      <div className="deposit-section">


        <div className="coustom_container">
          <div className="deposit_inner loader_main">
            {loading &&
              <div className="spinner_bg">

                <div className="color_ring">  <ColorRing
                  visible={true}
                  height="80"
                  width="80"

                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                /></div>

              </div>
            }
            <Row className="first_row">
              <Col lg={6} md={6} className="text-center">
                <div className="deposit-scanner">

                  <div className="scanner-pic">
                    <QRCode className="qr-code" value={barcodeValue ? barcodeValue : wallets?.bep20} />
                  </div>

                  <p>{t(`Send only ${barCodeHeding} to your address`)}</p>

                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="deposit-all-data ">
                  <h5>{walletHeding}</h5>
                  <div className="link-copy">
                    <input type='text' value={BEP20 ? BEP20 : wallets?.bep20} disabled></input>
                    <div className="icon-copy">
                      <FileCopyIcon onClick={() => { copyToClipboard(((walletHeding === "USDT - BEP20 Deposit") ? wallets?.bep20 : wallets?.trc20)) }} />
                    </div>
                  </div>

                  <p>
                    {t("Sending wrong tokens or to the wrong network, will result in a loss of fund")}
                  </p>
                  <div className="deposit-links">
                    <Link to="#" className="button_style" onClick={bep20wallet}>USDT - BEP20</Link>
                    {/* <Link to="#" className="button_style" onClick={trc20wallet}> USDT - TRC20 </Link> */}
                  </div>

                </div>
              </Col>
            </Row>

            {/* table section */}
            <div className="table_section">

              <div className="scrolling_table">
                <div className="scroll_under">
                  <Table className="support_table">
                    <thead>
                      <tr className="table_heading_row">
                        <th className="table_heading">#</th>
                        <th className="table_heading">{t("Amount")}</th>
                        <th className="table_heading">{t("Scan")}</th>
                        <th className="table_heading">{t("Date")}</th>
                      </tr>
                    </thead>
                    <tbody>

                      {fundValues?.map((item: any, index: any) => {
                        return (
                          <>
                            <tr className="table_data_row">
                              <td className="table_data"><div className="pic_text">{page > 1 ? index + keyCount + 1 : index + 1}</div></td>
                              <td className="table_data payment_fee">${item?.amount?.toFixed(4)}</td>
                              <td className="table_data">
                                <Link to={item?.blockscan_url} className="button_style" target="_blank"> View </Link>
                              </td>
                              <td className="table_data">{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                            </tr>
                          </>
                        )
                      })}

                    </tbody>
                  </Table>
                  {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={totalRecords / 10}
                    previousLabel="<"
                  />}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>
  );
};

export default Deposit;
