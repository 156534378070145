import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import _fetch from "../../config/api";
import { api_url, titleDescription } from "../../config/Config";
import toasted from "../../config/toast";
import Loader from "../../config/Loader";

const Login = (props: any) => {

  const [btnDisable, setbtnDisable] = useState(false);


  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });

  const Navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [otp, setotp] = useState();
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [selectCheckbox, setSelectCheckbox] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const submitLogin = async (e: any) => {
    setbtnDisable(true)
    e.preventDefault();
    let data = {
      "email": email,
      "password": password,
      // "otp": otp
    }
    let res = await _fetch(`${api_url}auth/login`, "POST", data, {})
    // if(selectCheckbox) {

    if (res?.status === 'success') {
      // if (res?.data?.user?.verified == true) {
      localStorage.setItem("auth", 'true');
      localStorage.setItem("accessToken", res?.token);
      localStorage.setItem("user", JSON.stringify(res?.data?.user));
      toasted.success(res?.message);
      Navigate("/dashboard");
      // } else {
      //   Navigate("/waiting-conformation", { state: res?.data?.user?.user_info?.name });
      // }

      setbtnDisable(false)

    }
    else {
      localStorage.clear();
      setbtnDisable(false)
      toasted.error(res?.message);
      // }
    }
    // else {
    //   toasted.error("Please select checkbox");
    // }
  }

  const [timer, setTimer] = useState('00:00');
  const sendOTP_ = async () => {
    var response = await _fetch(`${api_url}auth/generateAuthOtp`, 'POST', { "email": email }, {});

    toasted.success(response?.message)
    if (timer === "00:00") {
      var seconds: any = '180';
      var intervalId = window.setInterval(function () {
        seconds--;

        // if (seconds > 1) {
        let minutes: any = Math.floor(seconds / 60);
        let extraSeconds: any = seconds % 60;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
        var leftData = minutes + ":" + extraSeconds;

        setTimer(leftData);
        if (seconds == 0) {
          stop();
        }
      }, 1000);
    }
    let stop = () => clearInterval(intervalId);

  }

  return (
    // <main>
    <div className="login-body">
      {/* <div className="waves"> */}
      <div className="empty">
        <div className="coustom_container">
          <Row className="form-row">
            {/* <Col xl={6} lg={5} md={5} className="left-sec">
              <div className="login-pics">
                <img src={loginimg55} alt="loginimg" />
              </div>
            </Col> */}
            <Col xl={12} lg={12} md={12} className="login-col">
              <div className="waves">
                <div className="form-outer">
                  <div className="form-inner">
                    <div className="form-head">
                      <h2>Login</h2>
                      <p>Sign into your account</p>
                    </div>
                    <form className="form-start">
                      <input type="text" id="email" placeholder="Email Address" onChange={(e: any) => setEmail(e.target.value)} value={email} />

                      {/* <div className="hide-pass otp__sec">
                        <input type="number" id="otp" placeholder="Enter OTP" onChange={(e: any) => setotp(e.target.value)} />

                        <button type="button" className="btn otp_button" onClick={sendOTP_} disabled={timer == '00:00' ? false : true}> {timer == '00:00' ? 'Send OTP' : timer}</button>
                      </div> */}

                      <div className="hide-pass">
                        <input
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e: any) => setPassword(e.target.value)}
                          id="email"
                          placeholder="Password"
                        />

                        <button type="button" onClick={handleTogglePassword}>
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </button>
                      </div>
                      <div className="login-remfor">
                        <div className="login-check">
                          <input type="checkbox" id="rem-form" required />
                          <label className="me-label" htmlFor="rem-form" >Remember me</label>
                        </div>
                        <Link to="/forgot">Forgot Password</Link>
                      </div>
                      <button type="submit" className="log-btn" onClick={submitLogin} disabled={btnDisable}>

                        {btnDisable === true &&
                          <Loader LoaderTxt={'Please Wait...'} />
                        }
                        {btnDisable === false && 'Login '}

                      </button>
                      <div className="form-para">
                        <p>
                          Don't have an account? <Link to="/register">Register here</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* </div> */}
    </div>
    // </main>
  );
};

export default Login;
