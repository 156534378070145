import React, { useEffect, useState } from 'react'
import { api_url, titleDescription } from '../../config/Config';
import { Table } from 'react-bootstrap';
import _fetch from '../../config/api';
import dateformat from "dateformat";
import { ColorRing } from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
const TicketView = (props: any) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('');


    const onOpenModal = (image: any) => {
        setImage(image);
        setOpen(true);
    }
    const onCloseModal = () => setOpen(false);

    const [ticketHistory, setTicketHistory] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setpage] = useState(1);

    useEffect(() => {
        getTicketHistory();
    }, [page]);

    const getTicketHistory = async () => {
        setLoading(true);
        let res = await _fetch(`${api_url}support/ticket-history?limit=10&page=${page}`, "GET", {})
        if (res?.status === "success") {
            setLoading(false);
            setTicketHistory(res?.data);
            setTotalRecords(res?.total);
        }
    }

    return (
        <main>
            <div className="ticket_view">
                <div className="coustom_contanier">
                    <div className="ticket_inner loader_main">
                        {loading &&
                            <div className="spinner_bg">

                                <div className="color_ring">  <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"

                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                                /></div>

                            </div>
                        }
                        <h4>{t("TICKET HISTORY")}</h4>
                        <div className="table_section ">

                            <div className="scrolling_table">
                                <div className="scroll_under">
                                    <Table className="support_table">
                                        <thead>
                                            <tr className="table_heading_row">
                                                <th className="table_heading">{t("Subject")}</th>
                                                <th className="table_heading">{t("Description")}</th>
                                                <th className="table_heading">{t("Image")}</th>
                                                <th className="table_heading">{t("Date")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ticketHistory?.map((item: any, index: any) => {
                                                return (
                                                    <>
                                                        <tr className="table_data_row">
                                                            <td className="table_data">
                                                                <div className="pic_text">{item?.subject}</div>
                                                            </td>
                                                            <td className="table_data">{item?.description}</td>
                                                            <td className="table_data payment_fee">
                                                                <img src={item.image} onClick={() => onOpenModal(item.image)} alt={item?.subject + "img"} />
                                                            </td>
                                                            <td className="table_data"> {dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")} </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* image modal */}
            <Modal open={open} onClose={onCloseModal} center
                classNames={{
                    modal: "image_modal",
                }}>
                <img src={image} />

            </Modal>
        </main>
    )
}

export default TicketView