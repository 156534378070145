import React, { useEffect, useState } from 'react';
import _fetch from '../config/api';
import { title } from '../config/Config';
import Confirmation_gif from '../Assets/Icon/email-Confirmation.gif'
import { useLocation } from 'react-router-dom';


const WaitingScreen = (props: any) => {

    const location = useLocation()
    const details = location.state

    return (
        <div>
            <div className='data_as'>
                <div className='data___outer'>
                    <div className='form__ssd'>
                        <img height="200px" className='data__email_img' src={Confirmation_gif} alt="Confirmation-gif" />
                        <h2>Verify Your Email for Account Access</h2>

                        <h5 className='name___user mb-3'>Dear {details},</h5>

                        <p>Congratulations on creating your account! To access your user panel, please verify your email by following the instructions sent to your inbox.</p>

                        <p>Thank you for choosing us.</p>

                        <div className='text-end mt-4'>
                            <p className='mb-0'>Best regards,</p>
                            <p className='mb-0'> {title} Team</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default WaitingScreen;
