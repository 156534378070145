import site_logo from '../Assets/Icon/Logo.png';
import site_logo_dark from '../Assets/Icon/dark-logo.png';
export const logo = site_logo;
export const dark_logo = site_logo_dark;
export const title = 'GIC CAPITAL';
export const websiteName = 'GIC CAPITAL';
export const shortTitle = 'GIC CAPITAL';
export const titleDescription = 'GIC CAPITAL';
export const Image_path = site_logo;


// export const website = 'https://giccaptial.online/';
export const website = 'https://giccapital.online/';

// export const api_url = 'http://localhost:8000/api/v1/';

export const api_url = 'https://ap.giccapital.online/api/v1/';

// export const api_url = 'http://192.168.18.39:8000/api/v1/';
