import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { title, titleDescription } from '../config/Config';
// import shortLogo from '../Assets/Icon/shortLogo.png';
import About__icon from '../Assets/Icon/about.svg';


const About = () => {

    useEffect(() => {
        document.title = `About | ${titleDescription}`;
      });

    return (
        <>
            <div className='about-sx'>

                <section className='section-index section-banner align-items-center'>

                    <div className='container-banner mb-5'>

                        <div className='banner-top-continer'>
                            <div className='web-logo'>
                                <img src={About__icon} alt="about-icon" />
                            </div>
                            <h1 className='font-family-heading text-black'> About Us </h1>
                            <p className='text-black'> Our mission at {title} is to empower traders and financial professionals by providing cutting-edge tools, unparalleled support, and a diverse range of financial products. We are committed to fostering a dynamic trading environment where innovation meets reliability. Through continuous improvement and a client-centric approach, our mission is to facilitate success, growth, and financial prosperity for all our stakeholders.</p>
                            <Link className='btn' to="#"> Contact Us</Link>

                        </div>

                        <div className='trading-sec mt-5'>
                            <div className="tradingview-widget-container">
                                {/* <iframe src="https://www.tradingview-widget.com/embed-widget/ticker-tape/?locale=in#%7B%22symbols%22%3A%5B%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3AETHUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3ABTCUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3ABNBUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3ASOLUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3AAVAXUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3AXRPUSDT%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3AMATICUSDT%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Atrue%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A76%2C%22utm_source%22%3A%22in.tradingview.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22in.tradingview.com%2Fwidget%2Fticker-tape%2F%22%7D" title="ticker tape TradingView widget" lang="en"></iframe> */}
                                <iframe src="https://fxpricing.com/fx-widget/ticker-tape-widget.php?id=1,2,3,5,14,20&border=show&speed=50&click_target=blank&theme=light&tm-cr=FFFFFF&hr-cr=00000013&by-cr=28A745&sl-cr=DC3545&flags=rectangle&d_mode=regular&column=ask,bid&lang=en&font=Arial, sans-serif" title="ticker tape TradingView widget" lang="en"></iframe>
                            </div>
                        </div>

                    </div>
                </section>


                <section className='section-index about-sx_card'>
                    <div className='container-index'>
                        <div className='row'>
                            <div className='col-12 heading-sec'>
                                <h3 className='font-family-heading'>How It Works</h3>
                                {/* <p> Automate trades with math and probability</p> */}
                            </div>

                            <div className='col-lg-4 col-md-6 column-outer-sec'>
                                <div className='sec'>
                                    <div className='stap-sec'>
                                        <span>1</span>
                                    </div>
                                    <h2> FOREX TRADING ELEVATED</h2>
                                    <p> Embark on a journey into global markets with assurance through GIC Capital Services's all-encompassing forex trading services. Engage in major, minor, and exotic currency pairs with the confidence that GIC Capital is committed to optimizing your financial returns.</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 column-outer-sec'>
                                <div className='sec'>
                                    <div className='stap-sec'>
                                        <span>2</span>
                                    </div>
                                    <h2> BESPOKE ACCOUNTS, INDIVIDUALIZED SUPPORT </h2>
                                    <p>Select from a range of account types tailored to suit your distinct trading approach. GIC Capital provides standard accounts for beginners and advanced options for seasoned traders, ensuring a personalized experience for all..</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 column-outer-sec'>
                                <div className='sec'>
                                    <div className='stap-sec'>
                                        <span>3</span>
                                    </div>
                                    <h2> INFORMED TRADING WITH MARKET INSIGHTS </h2>
                                    <p>Stay at the forefront of market trends with GIC Capital s forward-looking market insights. Receive daily updates, technical analysis, and strategic market insights from our research team to empower informed and confident trading decisions. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>


                <section className='section-index about-sx_card about__sx_last'>
                    <div className='container-index'>
                        <div className='row'>
                            <div className='col-12 heading-sec'>
                                <h3 className='font-family-heading'>Start Your Forex Journey <br /> with GIC Capital</h3>
                                <p> ready to embark on a journey of financial growth and success? Sign up with GIC Capital today and experience the world of Forex trading like never before. Join a community of traders who trust us to deliver quality, reliability, and innovation in every aspect of their trading journey.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default About;
