import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import _fetch from "../../config/api";
import { api_url } from "../../config/Config";
import toasted from "../../config/toast";
import Loader from "../../config/Loader";


const Forgot = (props: any) => {

  const [email, setEmail] = useState("");
  const [btnDisable, setbtnDisable] = useState(false);
  const Navigate = useNavigate();

  const submitForm = async (e: any) => {
    setbtnDisable(true)
    e.preventDefault();

    let data = {
      "email": email,
    }

    let res = await _fetch(`${api_url}auth/forgotPassword`, "POST", data, {})
    if (res?.status === "success") {
      toasted.success(res?.message);
      setbtnDisable(false)
    } else {
      toasted.error(res?.message);
      setbtnDisable(false)
    }
  }


  useEffect(() => {
    document.title = props.pageTitle;
  }, []);
  return (
    <div className="login-body">
      <div className="empty">
        <div className="coustom_container">
          <Row className="form-row">
            {/* <Col xl={6} lg={5} md={5} className="left-sec">
              <div className="login-pics">
                <img src={loginimg55} alt="loginimg" />
              </div>
            </Col> */}
            <Col xl={12} lg={12} md={12}>
              <div className="waves">
                <div className="form-outer">
                  <div className="form-inner">
                    <div className="form-head">
                      <h2>Forgot Password</h2>
                      <p>Fill the details below</p>
                    </div>
                    <form className="form-start" onSubmit={submitForm}>
                      <input type="text" id="email" placeholder="Enter Email ID" onChange={(e: any) => setEmail(e.target.value)} value={email} />
                      {/* <input type="text" id="email" placeholder="Enter User ID" /> */}

                      <button type="submit" className="log-btn" disabled={btnDisable}>
                        
                      {btnDisable === true &&
                          <Loader LoaderTxt={'Please Wait...'} />
                        }
                        {btnDisable === false && 'Submit '}
                      </button>
                      <div className="form-para">
                        <p>
                          Still no account? <Link to="/register">Create New account</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Forgot