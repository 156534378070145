import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { api_url, titleDescription, website } from "../../config/Config";
import _fetch from "../../config/api";
import toasted from "../../config/toast";
import { ColorRing } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { getData } from 'country-list';
import Select, { SingleValue } from 'react-select';
import QRCode from 'qrcode.react';
import Authenticator from "./Authenticator";
import { useTranslation } from 'react-i18next';
import Loader from "../../config/Loader";



interface base64 {
  base64: string;
}

const Profile = (props: any) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const { selectedTab } = location.state || {};

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);


  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  type CountryOption = {
    value: string;
    label: string;
    flagUrl: string;
  };

  const CustomOption: React.FC<{ innerProps: any; label: string; data: CountryOption }> = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <img src={data.flagUrl} alt={label} style={{ marginRight: '8px' }} />
      {label}
    </div>
  );

  const countryOptions: CountryOption[] = getData().map((country) => ({
    value: country.code,
    label: country.name,
    flagUrl: `https://flagcdn.com/16x12/${country.code.toLowerCase()}.png`
  }));

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword2, setShowPassword2] = useState(false);
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [bio, setBio] = useState('');
  const [bep20Address, setBEP20Address] = useState('');
  const [trc20Address, setTRC20Address] = useState('');
  const [bep20AddressShow, setBEP20AddressShow] = useState('');
  const [trc20AddressShow, setTRC20AddressShow] = useState('');
  const [isOTP, setOTP] = useState('');
  const [loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState('');

  const [countryLabel, setCountryLabel] = useState('');
  const [countryFlagUrl, setCountryFlagUrl] = useState('');
  const [country, setCountry] = useState<CountryOption | null>({ value: '', label: countryLabel, flagUrl: countryFlagUrl });

  const [isAvatar, setAvatar] = useState<string>("");
  const [showtxt, setshowtxt] = useState(true);


  const handleTogglePassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const [password3, setPassword3] = useState("");
  const [showPassword3, setShowPassword3] = useState(false);

  const handleTogglePassword3 = () => {
    setShowPassword3(!showPassword3);
  };



  const Navigate = useNavigate();

  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
    getUserInfo();
  }, []);

  useEffect(() => {
    setCountry({
      value: '',
      label: countryLabel,
      flagUrl: countryFlagUrl
    });
  }, [countryLabel, countryFlagUrl]);

  const getUserInfo = async () => {
    setLoading(true);
    let res = await _fetch(`${api_url}user/userInfo/`, "GET", {});

    if (res?.status === "success") {
      setLoading(false);
      setUserEmail(res?.data?.data?.email);
      setFirstName(res?.data?.data?.user_info?.firstName);
      setLastName(res?.data?.data?.user_info?.lastName);
      setFullName(res?.data?.data?.user_info?.name);
      setAvatar(res?.data?.data?.user_info?.profile_image);
      setEmail(res?.data?.data?.email);
      setPhone(res?.data?.data?.user_info?.phone);
      setCountry(res?.data?.data?.user_info?.country);
      setCountryLabel(res?.data?.data?.user_info?.country);
      setCountryFlagUrl(res?.data?.data?.user_info?.country_flag);
      setAddress(res?.data?.data?.user_info?.bio);
      setBio(res?.data?.data?.user_info?.bio);
      setBEP20Address(res?.data?.data?.user_info?.wallet_addresses?.BEP20);
      setTRC20Address(res?.data?.data?.user_info?.wallet_addresses?.TRC20);
      setBEP20AddressShow(res?.data?.data?.user_info?.wallet_addresses?.BEP20);
      setTRC20AddressShow(res?.data?.data?.user_info?.wallet_addresses?.TRC20);
    }
  }

  const [profileOTP, setprofileOTP] = useState();

  const updateProfile = async () => {
    setbtnDisable(true);
    let data = {
      "firstName": firstName,
      "lastName": lastName,
      "country": country?.label,
      "country_flag": country?.flagUrl,
      "bio": address ? address : "address",
      "otp": profileOTP,

    }

    let d = await _fetch(`${api_url}auth/profileUpdate`, "POST", data, {})
    if (d?.status === "success") {
      toasted.success(d?.message);
      getUserInfo();
      setbtnDisable(false);

    }
    else {
      setbtnDisable(false);
      toasted.error(d?.message);
    }

  }

  const [passwordBtnDisable, setpasswordBtnDisable] = useState(false)

  const updatePassword = async () => {
    setpasswordBtnDisable(true);
    let data = {
      "current_password": password,
      "new_password": password2,
      "confirm_new_password": password3
    }
    let result = await _fetch(`${api_url}user/changePassword`, "POST", data, {})
    if (result?.status === "success") {
      toasted.success(result?.message);
      Navigate("/login");
      setpasswordBtnDisable(false);
    }
    else {
      toasted.error(result?.message);
      setpasswordBtnDisable(false);
    }
  }

  const [walletOTP, setwalletOTP] = useState();
  const [walletBtnDisable, setwalletBtnDisable] = useState(false);

  const updateWalletAddress = async () => {
    setwalletBtnDisable(true);
    let data = {
      "bep20": bep20Address,
      // "trc20": trc20Address,
      "otp": walletOTP
    }
    let resvalue = await _fetch(`${api_url}auth/walletaddressupdate`, "POST", data, {})
    if (resvalue?.status === "success") {
      toasted.success(resvalue?.message);
      getUserInfo();
      setwalletBtnDisable(false);
    }
    else {
      setwalletBtnDisable(false);
      toasted.error(resvalue?.message);
    }
  }

  const handleFirstNameChange = (event: any) => {
    let inputValue = event.target.value;
    const regex = /^[a-zA-Z]*$/;
    if (regex.test(inputValue)) {
      setFirstName(inputValue);
    }
  }

  const handleLastNameChange = (event: any) => {
    let inputValue = event.target.value;
    const regex = /^[a-zA-Z]*$/;
    if (regex.test(inputValue)) {
      setLastName(inputValue);
    }
  }


  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  // const handleFileRead = async (event: any) => {
  //   const file = event.target.files[0]
  //   let base64 = await convertBase64(file)
  //   setAvatar(String(base64));
  // }
  //profile_image
  const imageUpload = async (event: any) => {
    const file = event.target.files[0]
    let base64 = await convertBase64(file)

    let postData = {
      "profile_image": String(base64)
    }

    let result = await _fetch(`${api_url}auth/profileImageUpdate`, "POST", postData, {})
    if (result?.status === "success") {
      toasted.success(result?.message);
      getUserInfo();
    } else {
      toasted.error(result?.message)
    }

  }



  const [timer, setTimer] = useState('00:00');
  const sendOTP_ = async () => {
    var response = await _fetch(`${api_url}auth/generateOTP?check=${userEmail}&email_type=email`, 'GET', {}, {});

    toasted.success(response?.message)
    if (timer === "00:00") {
      var seconds: any = '180';
      var intervalId = window.setInterval(function () {
        seconds--;

        // if (seconds > 1) {
        let minutes: any = Math.floor(seconds / 60);
        let extraSeconds: any = seconds % 60;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
        var leftData = minutes + ":" + extraSeconds;

        setTimer(leftData);
        if (seconds == 0) {
          stop();
        }
      }, 1000);
    }
    let stop = () => clearInterval(intervalId);

  }

  return (
    <main>
      <div className="profile1-section">
        <div className="coustom_container">
          <Tabs defaultIndex={selectedTab === "Wallet" ? 2 : 0} >
            <Row>

              <Col lg={4} md={4}>
                <div className="personal-info">
                  <div className="my-info">
                    <b>{t("My Profile")}</b>
                  </div>
                  <div className="profile-infos">
                    <div className="name-pic">
                      <div className="outer_profile">


                        <div className="left_profile">

                          {isAvatar == "" || isAvatar == null && <label htmlFor="upload">
                            <div className="emptyPlaceholder text-center">


                              <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                <path d="M3 4V1h2v3h3v2H5v3H3V6H0V4h3zm3 6V7h3V4h7l1.83 2H21c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V10h3zm7 9c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-3.2-5c0 1.77 1.43 3.2 3.2 3.2s3.2-1.43 3.2-3.2-1.43-3.2-3.2-3.2-3.2 1.43-3.2 3.2z">
                                </path>
                              </svg>


                              <h6 >{t("Upload photo")}</h6>
                            </div>
                            <span>
                              <input
                                type="file"
                                id="upload"
                                style={{ display: "none" }}
                                onChange={(event: any) => { imageUpload(event); }} />
                            </span>
                          </label>}

                          {isAvatar && <img src={isAvatar}
                            alt="user-icon"
                            width="100%"
                          />}



                        </div>
                      </div>



                      <div className="name-post">
                        <h6>{t("Name")}</h6>
                        <h5>{fullName ? fullName : "-"}</h5>
                      </div>
                    </div>

                  </div>
                  <div className="bio">
                    <b>{t("About")}</b>
                    <p>
                      {bio ? bio : "-"}
                    </p>
                  </div>
                  <TabList>
                    <Tab>{t("Account")}</Tab>
                    <Tab>{t("Password")}</Tab>
                    <Tab>{t("Wallet")}</Tab>
                    <Tab>{t("Authenticator App")}</Tab>
                  </TabList>

                </div>
              </Col>
              <Col lg={8} md={8}>
                <div className="account1-details loader_main">

                  {loading &&
                    <div className="spinner_bg">
                      <div className="color_ring">  <ColorRing
                        visible={true}
                        height="80"
                        width="80"

                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                      /></div>

                    </div>
                  }


                  <TabPanel>
                    <div className="accout-seting">
                      <h2>{t("Account Setting")}</h2>
                      <div className="form-part">

                        <Col md={6}>
                          <div className="form">
                            <label className="form-label">{t("First Name")}</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="First Name"
                              onChange={(e) => handleFirstNameChange(e)}
                              value={firstName ? firstName : fullName}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form">
                            <label className="form-label">{t("Last Name")}</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Last Name"
                              onChange={(e) => handleLastNameChange(e)}
                              value={lastName ? lastName : ""}
                            />
                          </div>
                        </Col>
                      </div>
                      <div className="form-part country_part">
                        <Col md={6}>
                          <div className="form">
                            <label className="form-label">{t("Country")}</label>
                            <Select
                              options={countryOptions}
                              value={country}
                              onChange={(option: SingleValue<CountryOption>) => setCountry(option)}
                              components={{ Option: CustomOption }}
                              isSearchable
                              placeholder="Select a country..."
                            // defaultValue={country}
                            />

                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="form">
                            <label className="form-label">{t("Email")} </label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="@gmail.com"
                              disabled
                              value={email}
                            />
                          </div>
                        </Col>
                      </div>
                      <div className="bottom_sec">
                        {/* <Col lg={12}>
                          <div className="form">
                            <label className="form-label">Phone </label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="0000000000"
                              onChange={(e) => setPhone(e.target.value)}
                              value={phone}
                            />
                          </div>
                        </Col> */}
                        <Col md={12}>
                          <div className="form mb-0">
                            <label className="form-label">{t("Bio")} </label>
                            <textarea placeholder='Address' rows={5} id='address' onChange={(e: any) => setAddress(e.target.value)} value={address ? address : ""} ></textarea>
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="form">
                            <label className="form-label">{t("Auth code")}</label>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Auth Code"
                              onChange={(e: any) => setprofileOTP(e.target.value)}
                              value={profileOTP}
                            />
                          </div>
                        </Col>

                      </div>


                      <div className="">
                        <Col lg={6}>
                          <div className="acct-follow">
                            <button className="button_style" onClick={updateProfile} disabled={btnDisable}>

                              {btnDisable === true &&
                                <Loader LoaderTxt={'Please Wait...'} />
                              }
                              {btnDisable === false && `${t("Update")}`}


                            </button>
                          </div>
                        </Col>

                      </div>



                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="accout-seting">
                      <h2>{t("Password Setting")}</h2>

                      <div className="form-part2 ">
                        <Col lg="12">
                          <label>{t("Old Password")}</label>
                          <div className="hide-pass">
                            <input
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="Password"
                              className="form-control"
                            />

                            <button
                              type="button"
                              onClick={handleTogglePassword}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </button>
                          </div>
                        </Col>

                        <Col lg="12">
                          <label>{t("New Password")}</label>
                          <div className="hide-pass">
                            <input
                              type={showPassword2 ? "text" : "password2"}
                              value={password2}
                              onChange={(e) => setPassword2(e.target.value)}
                              placeholder="Password"
                              className="form-control"
                            />

                            <button
                              type="button"
                              onClick={handleTogglePassword2}
                            >
                              {showPassword2 ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </button>
                          </div>
                        </Col>

                        <Col lg="12">
                          <label>{t("Confirm New Password")}</label>
                          <div className="hide-pass">
                            <input
                              type={showPassword3 ? "text" : "password3"}
                              value={password3}
                              onChange={(e) => setPassword3(e.target.value)}
                              placeholder="Password"
                              className="form-control"
                            />

                            <button
                              type="button"
                              onClick={handleTogglePassword3}
                            >
                              {showPassword3 ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </button>
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="acct-follow">
                            <button className="button_style" onClick={updatePassword} disabled={passwordBtnDisable}>
                              {passwordBtnDisable === true &&
                                <Loader LoaderTxt={'Please Wait...'} />
                              }
                              {passwordBtnDisable === false && `${t("Update")}`}
                            </button>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="accout-seting">
                      <h2>{t("Wallet")}</h2>
                      <div className="form">
                        <label className="form-label">BEP20</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="BEP20 Address"
                          onChange={(e) => setBEP20Address(e.target.value)}
                          value={bep20Address}
                        />

                      </div>
                      {/* {!bep20AddressShow ? <> */}

                      <div className="form">
                        <label className="form-label">{t("Auth code")}</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Auth Code"
                          onChange={(e: any) => setwalletOTP(e.target.value)}
                          value={walletOTP}
                        />

                      </div>
                      {/* </>
                        :
                        ""
                      } */}
                      {/* <div className="form">
                        <label className="form-label">TRC20</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="TRC20"
                          onChange={(e) => setTRC20Address(e.target.value)}
                          value={trc20Address}
                        />
                      </div> */}
                      {/* <button className="otp-button btn ms-3" onClick={sendOTP_} >{timer !== "00:00" ? timer : "Send OTP"}</button> */}


                      {/* {(!bep20AddressShow && !trc20AddressShow) && <>
                        <div className="form">
                          <label className="form-label">OTP </label>
                          <div className="d-flex justify-conetent-between align-items-center">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="OTP"
                              onChange={(e) => setOTP(e.target.value)}
                              value={isOTP}
                            />
                            <button className="otp-button btn ms-3" onClick={sendOTP_} >{timer !== "00:00" ? timer : "Send OTP"}</button>
                          </div>

                        </div> */}


                      <Col lg={6}>
                        <div className="acct-follow">

                          <button className="button_style" onClick={updateWalletAddress} disabled={walletBtnDisable}>
                            {walletBtnDisable === true &&
                              <Loader LoaderTxt={'Please Wait...'} />
                            }
                            {walletBtnDisable === false && `${t("Update")}`}
                          </button>

                        </div>
                      </Col>
                    </div>
                  </TabPanel>

                  <TabPanel className="h-100">
                    <Authenticator />
                  </TabPanel>

                </div>
              </Col>

            </Row>
          </Tabs>
        </div>
      </div>
    </main>
  );
};

export default Profile;
